import axios from "axios";
let endpoint = "/api";

async function getData(path) {
  try {
    return (await axios.get(`${endpoint}/${path}`)).data;
  } catch (err) {
    throw buildError(err);
  }
}

async function postData(path, data) {
  try {
    return await axios.post(`${endpoint}/${path}`, data);
  } catch (err) {
    throw buildError(err);
  }
}

async function patchData(path, data) {
  try {
    await axios.patch(`${endpoint}/${path}`, data);
  } catch (err) {
    throw buildError(err);
  }
}

function buildError(err) {
  const httpErrorCode = err?.response?.status ?? -1;
  const error = {
    httpErrorCode,
    errorType: "",
    errorMessage: err.message,
    errors: [],
    method: err.config.url,
  };
  if (httpErrorCode === 401 || httpErrorCode === 403) {
    error.errorType = "auth";
  } else if (httpErrorCode.toString().startsWith("4")) {
    error.errorType = "client";
  } else if (httpErrorCode.toString().startsWith("5")) {
    error.errorType = "server";
  } else {
    error.errorType = "other";
  }

  let resErrors = err?.response?.data?.errors;
  if (Array.isArray(resErrors)) {
    for (let e of resErrors) {
      if (e?.text) error.errors.push(e.text);
    }
  } else if (typeof err !== "string") {
    error.errors.push(err.toJSON().message);
  }
  return error;
}

export const seatData = {
  getMPIDCanvassData: async (areaID) => {
    return await getData(`seatData/mpidCanvassData/${areaID}`);
  },
  scoreProfile: async (areaID, score) => {
    return await getData(`seatData/scoreProfile/${areaID}/${score}`);
  },
  scorePerformance: async (areaID, score) => {
    return await getData(`seatData/scorePerformance/${areaID}/${score}`);
  },
  pollingDay: async (areaID, date) => {
    return await getData(`seatData/pollingDay/${areaID}/${date}`);
  },
  shuttleworthResponse: async (areaID) => {
    return await getData(`seatData/shutResponse/${areaID}`);
  },
  switchAnalysis: async (areaID, oldPeriod, newPeriod) => {
    return await getData(`seatData/switchAnalysis/${areaID}/${oldPeriod}/${newPeriod}`);
  },
  info: async (areaID) => {
    return await getData(`seatData/info/${areaID}`);
  },
  geTomorrow: async (areaID) => {
    return await getData(`seatData/geTomorrow/${areaID}`);
  },
  pdCodes: async (areaID) => {
    return await getData(`seatData/tellingCodes/${areaID}`);
  },
  childAreas: async (areaID) => {
    return await getData(`seatData/childAreas/${areaID}`);
  },
  surveyQuestions: async (areaID) => {
    return await getData(`seatData/surveyQuestions/${areaID}`);
  },
  activistCodes: async (areaID) => {
    return await getData(`seatData/activistCodes/${areaID}`);
  },
  walkSummary: async (areaID) => {
    return await getData(`seatData/walkPDSummary/${areaID}`);
  },
  walkData: async (regArea, pd) => {
    return await getData(`seatData/walkPD/${regArea}/${pd}`);
  },
  walkAreaSummary: async (areaID) => {
    return await getData(`seatData/walkAreaSummary/${areaID}`);
  },
  leTomorrow: async (areaID) => {
    return await getData(`seatData/leTomorrow/${areaID}`);
  },
  walkAreaSummary: async (areaID) => {
    return await getData(`seatData/walkAreaSummary/${areaID}`);
  },
  voteHistorySummary: async (areaID) => {
    return await getData(`seatData/voteHistorySummary/${areaID}`);
  },
  parentVoteHistorySummary: async (areaID) => {
    return await getData(`seatData/voteHistorySummaryParent/${areaID}`);
  },
  pvUploadSummary: async (areaID) => {
    return await getData(`seatData/pvUploadSummary/${areaID}`);
  },
};

export const metaData = {
  registerStatus: async () => {
    return await getData(`metaData/registerStatus`);
  },
  myCampaignStats: async () => {
    return await getData(`metaData/myCampaignStats`);
  },
  topCanvassers: async () => {
    return await getData(`metaData/topCanvassers`);
  },
  getUserList: async () => {
    return await getData(`metaData/dashboardUsers`);
  },
  topForms: async () => {
    return await getData(`metaData/topForms`);
  },
  formStats: async (formID) => {
    return await getData(`metaData/forms/${formID}`);
  },
  allForms: async () => {
    return await getData(`metaData/formsOverallProgress`);
  },
  minivanSyncs: async () => {
    return await getData(`metaData/minivanSyncs`);
  },
  emailEvents: async (email) => {
    return await getData(`metaData/emailEvents/${email}`);
  },
  pdhaGeocodes: async (table, LA, PD) => {
    return await getData(`metaData/pdha/geocodes/${table}/${LA}/${PD}`);
  },
  pdhaBoundary: async (table, area) => {
    return await getData(`metaData/pdha/kml/${table}/${area}`);
  },
  mpCanvassers: async () => {
    return await getData(`metaData/mpCanvassers`);
  },
  missingCanvassers: async () => {
    return await getData(`metaData/missingCanvassers`);
  },
  postalVoteClears: async () => {
    return await getData(`metaData/postalVotes/wipes`);
  },
};

export const committeeData = {
  list: async () => {
    return await getData(`metaData/committees`);
  },
  users: async (committeeID) => {
    return await getData(`metaData/committees/${committeeID}/users`);
  },
  emailUse: async (committeeID) => {
    return await getData(`metaData/committees/${committeeID}/emailUse`);
  },
  info: async (committeeID) => {
    return await getData(`metaData/committees/${committeeID}`);
  },
  emailSends: async (committeeID) => {
    return await getData(`metaData/committees/${committeeID}/emailSends`);
  },
  emailContent: async (committeeID, batchEmailJobID) => {
    return await getData(`metaData/committees/${committeeID}/emailContent/${batchEmailJobID}`);
  },
  emailSubsOverTime: async (committeeID) => {
    return await getData(`metaData/committees/${committeeID}/emailOverTime `);
  },
};

export const nationalData = {
  geTomorrow: async () => {
    return await getData(`nationalData/geTomorrow`);
  },
  dailyContact: async () => {
    return await getData(`nationalData/dailyContact`);
  },
  emailSends: async (committeeID) => {
    return await getData(`metaData/committees/${committeeID}/emailSends`);
  },
  emailContent: async (committeeID, batchEmailJobID) => {
    return await getData(`metaData/committees/${committeeID}/emailContent/${batchEmailJobID}`);
  },
};

export const userData = {
  allowedConstituencies: async () => {
    return await getData(`allowed`);
  },
  roles: async () => {
    return await getData(`roles`);
  },
  functions: async () => {
    return await getData(`functions`);
  },
  whoami: async () => {
    return await getData(`whoami`);
  },
  getUserDistricts: async (userID) => {
    return [
      { code: "E14001037", name: "Westmorland and Lonsdale", fieldcode: "W" },
      { code: "E14001060", name: "Yeovil", fieldcode: "W" },
    ];
  },
  getAllAreas: async () => {
    return await getData(`allowed`);
  },
};

export const userManage = {
  userList: async () => {
    return await getData(`userManage/list`);
  },
  addUser: async (data) => {
    return await postData(`userManage/add`, data);
  },
  userData: async (userID) => {
    return await getData(`userManage/user/${userID}`);
  },
  getUserRoles: async () => {
    return await getData("userManage/roles");
  },
  editUserRoles: async (userID, data) => {
    return await patchData(`userManage/${userID}/roles`, data);
  },
  editUserDistricts: async (userID, data) => {
    return await patchData(`userManage/${userID}/districts`, data);
  },
  updateUserStatus: async (userID, data) => {
    return await postData(`userManage/${userID}/active`, data);
  },
};

export default {
  committeeData,
  metaData,
  nationalData,
  seatData,
  userData,
  userManage,
};
