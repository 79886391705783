import React, { useState } from "react";
import { useMap, Map, AdvancedMarker } from "@vis.gl/react-google-maps";
import { Helmet } from "react-helmet-async";
import { List, ListIcon, ListItem, Message } from "semantic-ui-react";
import api from "../../logic/apiHandler";
import BoundaryForm from "./MapBoundaryForm";
import LAForm from "./MapLAForm";
import MapBoundary from "./MapBoundary";

export default function PDHACheckup() {
  const [markers, setMarkers] = useState([]);
  const [boundsChanged, setBoundsChanged] = useState(false);
  const [zoomLevel, setZoomLevel] = useState(5.3);
  const [polygons, setPolygons] = useState([]);
  const [colours, setColours] = useState(["#0303FC", "#FAA61A", "#E60B0B", "#30f205"]);
  const [errors, setErrors] = useState([]);
  const [deletedPolygon, setDeletedPolygon] = useState({});
  const map = useMap();
  const GOOGLE_MAP_ID = process.env.REACT_APP_GOOGLE_MAP_ID;

  const errorMessages = {
    query: "Cannot get boundary coordinates. Please try again in a few minutes.",
    boundary: "Invalid boundary ONS",
    pollingDistrict: "Polling district not found",
    boundaryAdded: "Boundary already added to map",
    LA: "Invalid LA",
    PD: "Invalid PD",
  };

  async function drawPolygon(boundary, layer) {
    let coordinates;
    if (polygons.find((polygon) => polygon.ons === boundary)) {
      addError(errorMessages.boundaryAdded);
    } else {
      try {
        const res = await api.metaData.pdhaBoundary(layer, boundary);
        coordinates = getPolygonCoordinates(res);
        removeError(errorMessages.query);
      } catch (e) {
        addError(errorMessages.query);
        console.error(e);
        return;
      }

      if (!coordinates) {
        addError(errorMessages.boundary);
      } else {
        removeError(errorMessages.boundary);
        const colour = colours[0];
        colours.shift();
        setColours(colours);
        const polygon = new google.maps.Polygon({
          paths: coordinates,
          strokeColor: colour,
          strokeOpacity: 0.6,
          strokeWeight: 2,
          fillColor: colour,
          fillOpacity: 0.25,
        });
        setPolygons([...polygons, { ons: boundary, colour, coordinates, polygonDetails: polygon }]);
      }
      centreMap(coordinates);
    }
  }

  function getPolygonCoordinates(data) {
    let res = data.split("<coordinates>")[1].split("</coordinates>")[0];
    res = res.split(" ");

    return res.map((row) => {
      let coordinates = row.split(",");
      return { lat: parseFloat(coordinates[1]), lng: parseFloat(coordinates[0]) };
    });
  }

  function removePolygon(polygonToRemove) {
    setDeletedPolygon(polygonToRemove);
    setPolygons(polygons.filter((polygon) => polygon.ons !== polygonToRemove.ons));
    setColours([...colours, polygonToRemove.colour]);
  }

  function centreMap(coordinates) {
    const bounds = new google.maps.LatLngBounds();
    if (!boundsChanged) {
      coordinates.forEach((coord) => {
        bounds.extend({ lat: coord.latitude ?? coord.lat, lng: coord.longitude ?? coord.lng });
      });
    } else {
      const currBounds = map.getBounds();
      coordinates.forEach((coord) => {
        bounds.extend({ lat: coord.latitude ?? coord.lat, lng: coord.longitude ?? coord.lng });
      });
      bounds.union(currBounds);
    }

    map.fitBounds(bounds);
    map.panToBounds(bounds);
    setBoundsChanged(true);
  }

  async function addMarkers(LA, PD, table) {
    try {
      const markerGeocodes = await api.metaData.pdhaGeocodes(table, LA, PD);
      if (markerGeocodes.length) {
        removeError(errorMessages.pollingDistrict);
        setMarkers(markerGeocodes);
        centreMap(markerGeocodes);
      } else {
        addError(errorMessages.pollingDistrict);
      }
    } catch (e) {
      addError(errorMessages.pollingDistrict);
    }
  }

  function addError(error) {
    setErrors((prevState) => [...new Set([...prevState, error])]);
  }

  function removeError(error) {
    setErrors((prevState) => prevState.filter((str) => str !== error));
  }

  function renderActiveBoundaries() {
    return polygons.map((polygon) => {
      return (
        <ListItem key={polygon.ons} style={{ color: polygon.colour, cursor: "pointer" }}>
          <ListIcon
            name="delete"
            style={{ color: polygon.colour, cursor: "pointer" }}
            onClick={() => removePolygon(polygon)}
          />
          {polygon.ons}
        </ListItem>
      );
    });
  }

  return (
    <div>
      <Helmet>PDHA Checkup</Helmet>
      <LAForm
        addMarkers={addMarkers}
        setMarkers={setMarkers}
        addError={addError}
        removeError={removeError}
        errorMessages={errorMessages}
      />
      <BoundaryForm
        drawPolygon={drawPolygon}
        polygons={polygons}
        addError={addError}
        removeError={removeError}
        errorMessages={errorMessages}
      />
      {errors.length ? <Message error header="Unable to complete your request" list={errors} /> : <></>}
      <List horizontal>{renderActiveBoundaries()}</List>
      <Map
        mapId={GOOGLE_MAP_ID}
        style={{ height: window.innerHeight * 0.7 }}
        defaultBounds={{
          north: 60.86,
          south: 49.86,
          west: -8.65,
          east: 1.76,
        }}
        zoom={zoomLevel}
        onZoomChanged={(e) => setZoomLevel(e.detail.zoom)}
      >
        {markers.map((marker) => {
          return (
            <AdvancedMarker position={{ lat: marker.latitude, lng: marker.longitude }}>
              {marker.occupation > 0 ? (
                <img src={"https://maps.gstatic.com/intl/en_us/mapfiles/markers2/measle.png"} className="pin-icon" />
              ) : (
                <img
                  src={"https://maps.gstatic.com/intl/en_us/mapfiles/markers2/measle_blue.png"}
                  className="pin-icon"
                />
              )}
            </AdvancedMarker>
          );
        })}
        <MapBoundary polygons={polygons} deletedPolygon={deletedPolygon} />
      </Map>
    </div>
  );
}
