import React, { useEffect, useState } from "react";
import api from "../../logic/apiHandler";
import { useParams } from "react-router-dom";
import { Grid, GridColumn, GridRow, StatisticValue, StatisticLabel, Statistic } from "semantic-ui-react";
import FormStatsBarChart from "./FormStatsBarChart";

export default function FormStatsScreen() {
  let { formID } = useParams();
  const [formStats, setFormStats] = useState({});
  const [dataLoaded, setDataLoaded] = useState(false);

  useEffect(() => {
    (async () => {
      setDataLoaded(false);
      try {
        setFormStats(await api.metaData.formStats(formID));
        setDataLoaded(true);
      } catch (e) {
        console.log("e", e);
        setDataLoaded(true);
      }
    })();
  }, [formID]);

  return (
    <div>
      <h2>Form: {formStats.internalName}</h2>
      <h4>Public name: {formStats.displayName}</h4>
      {dataLoaded && (
        <Grid stackable columns={2}>
          <GridRow>
            <GridColumn width={8}>
              <Statistic horizontal>
                <StatisticValue>{formStats.count}</StatisticValue>
                <StatisticLabel>total responses</StatisticLabel>
              </Statistic>
            </GridColumn>
          </GridRow>
          <GridRow>
            <GridColumn mobile={16} tablet={8} computer={6} largeScreen={8} widescreen={6}>
              <h3>Last 2 Months</h3>
              <FormStatsBarChart data={formStats.last2Months} type="last2Months" />
            </GridColumn>
            <GridColumn mobile={16} tablet={8} computer={6} largeScreen={8} widescreen={6}>
              <h3>Last 2 Days</h3>
              <FormStatsBarChart data={formStats.last2Days} type="last2Days" />
            </GridColumn>
          </GridRow>
        </Grid>
      )}
    </div>
  );
}
