import React, { useContext, useEffect, useState } from "react";
import { flexRender, getCoreRowModel, getSortedRowModel, useReactTable } from "@tanstack/react-table";
import { Helmet } from "react-helmet-async";
import { Button, Header, Icon } from "semantic-ui-react";
import Dimmer from "../shared/Dimmer";
import api from "../../logic/apiHandler";
import { canAccess } from "../../logic/userPermissionsHandler";
import { UserContext } from "../shared/UserContext";

export default function PostalVoteClears() {
  const user = useContext(UserContext);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [error, setError] = useState(null);
  const [postalVoteClears, setPostalVoteClears] = useState([]);
  const [canApprove] = useState(canAccess(user, "approvePVClears"));

  useEffect(() => {
    (async () => {
      setDataLoaded(false);
      setError(null);

      try {
        setPostalVoteClears(await api.metaData.postalVoteClears());
        setDataLoaded(true);
      } catch (e) {
        console.log(e);
        setError(e);
        setDataLoaded(true);
      }
    })();
  }, [user]);

  const columns = [
    {
      header: "Request ID",
      accessorKey: "requestID",
    },
    {
      header: "Forename",
      accessorKey: "forename",
    },
    {
      header: "Surname",
      accessorKey: "surname",
    },
    {
      header: "Email",
      accessorKey: "email",
    },
    {
      header: "Date Requested",
      accessorKey: "dateRequested",
    },
    {
      header: "Date Processed",
      accessorKey: "dateProcessed",
      cell: ({ row }) => row.original.dateProcessed || "-",
    },
    {
      header: "Areas",
      accessorKey: "areas",
      cell: ({ row }) => formatAreas(row.original.areas),
    },
    {
      id: "approveRequest",
      cell: ({ row }) =>
        row.original.dateProcessed ? (
          <></>
        ) : (
          <Button onClick={(e) => handleClick(e, row)} style={{ backgroundColor: "#FAA61A" }}>
            Approve Request
          </Button>
        ),
    },
  ];

  function formatAreas(areas) {
    return (
      <ul style={{ columnCount: 2, listStyleType: "circle" }}>
        {areas.split(/,/).map((area) => {
          return (
            <li key={area} style={{ listStyleType: "circle", textAlign: "left" }}>
              {area.trim()}
            </li>
          );
        })}
      </ul>
    );
  }

  function handleClick(e, row) {
    console.log("e", e);
    console.log("data", row.original);
  }

  const table = useReactTable({
    columns,
    data: postalVoteClears,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    initialState: {
      sorting: [
        {
          id: "dateRequested",
          desc: true,
        },
      ],
    },
    state: {
      columnVisibility: {
        approveRequest: canApprove,
      },
    },
  });

  return (
    <div>
      <Helmet>
        <title>Recent Postal Vote Clears</title>
      </Helmet>
      <Header as="h2">Recent Postal Vote Requests</Header>
      <Dimmer dataLoaded={dataLoaded} error={error}>
        <table style={{ minWidth: "100%" }}>
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th key={header.id} colSpan={header.colSpan}>
                    <div
                      className={header.column.getCanSort() ? "cursor-pointer select-none" : ""}
                      onClick={header.column.getToggleSortingHandler()}
                      title={
                        header.column.getCanSort()
                          ? header.column.getNextSortingOrder() === "asc"
                            ? "Sort ascending"
                            : header.column.getNextSortingOrder() === "desc"
                            ? "Sort descending"
                            : "Clear sort"
                          : undefined
                      }
                    >
                      {flexRender(header.column.columnDef.header, header.getContext())}
                      {{
                        asc: <Icon name="caret up" color="yellow" />,
                        desc: <Icon name="caret down" color="yellow" />,
                      }[header.column.getIsSorted()] ?? null}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => {
              return (
                <tr key={row.id}>
                  {row.getVisibleCells().map((cell) => {
                    return <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>;
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </Dimmer>
    </div>
  );
}
