import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import ChesterFormulaPercentagesTable from "./ChesterFormulaPercentagesTable";
import ConstituencyChesterFormulaTracker from "./ConstituencyChesterFormulaTracker";
import ConstituencyChesterPrediction from "./ConstituencyChesterPredictionBarChart";
import Dimmer from "../shared/Dimmer";
import { seatData } from "../../logic/apiHandler";
import { calculateBarChartData, calculateMonthlyChesterData } from "../../logic/geCanvassDataProcessor";
import { getGraphingData2 } from "../../logic/sharedProcessor";
import { Header, Grid, GridRow, GridColumn, StatisticValue, StatisticLabel, Statistic } from "semantic-ui-react";
import { Helmet } from "react-helmet-async";

export default function LocalElections({ areaName }) {
  const [leData, setLEData] = useState([]);
  const [totalCanvasses, setTotalCanvasses] = useState("-");
  const [dataLoaded, setDataLoaded] = useState(false);
  const [error, setError] = useState(null);
  let { areaID } = useParams();

  useEffect(() => {
    async function getLEData() {
      setDataLoaded(false);
      setError(null);

      try {
        let data = await seatData.leTomorrow(areaID);
        setLEData(data);
        setTotalCanvasses(data.reduce((a, c) => a + (c.response === "Unk" ? 0 : c.responseCount), 0));
        setDataLoaded(true);
      } catch (e) {
        console.log(e);
        setError(e);
        setDataLoaded(true);
      }
    }

    getLEData();
  }, [areaID]);

  return (
    <div>
      <Header as="h2">LE canvassing</Header>
      <Helmet>
        <title>{areaName ?? ""} - LE Canvassing</title>
      </Helmet>
      <Dimmer dataLoaded={dataLoaded} error={error}>
        <Grid stackable columns={12}>
          <GridRow>
            <GridColumn width={8}>
              <Statistic horizontal>
                <StatisticValue>{totalCanvasses}</StatisticValue>
                <StatisticLabel>responses since June 2023</StatisticLabel>
              </Statistic>
            </GridColumn>
          </GridRow>
          <GridRow>
            <GridColumn width={8}>
              <ConstituencyChesterFormulaTracker data={getGraphingData2(leData, 30, 547)} />
            </GridColumn>
            <GridColumn width={4}>
              <h3 className="title">
                Constituency Chester Prediction
                <br />
                All LE canvassing
              </h3>
              <ConstituencyChesterPrediction data={calculateBarChartData(leData)} />
            </GridColumn>
          </GridRow>
          <GridRow>
            <GridColumn width={12}>
              <ChesterFormulaPercentagesTable data={calculateMonthlyChesterData(leData)} />
            </GridColumn>
          </GridRow>
        </Grid>
      </Dimmer>
    </div>
  );
}
