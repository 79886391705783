import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ComposedChart, Line, Bar, XAxis, YAxis, CartesianGrid } from "recharts";
import api from "../../logic/apiHandler";
import { getShuttleworthGraphingData } from "../../logic/shuttleworthDataProcessor";
import { Helmet } from "react-helmet-async";
import { Container } from "semantic-ui-react";
import Dimmer from "../shared/Dimmer";

export default function ShuttleworthResponseGraph({ areaName }) {
  let { areaID } = useParams();
  const [shuttleworthResponseData, setShuttleworthResponseData] = useState(null);
  const [fullName, setFullName] = useState(null);
  const [referenceDate, setReferenceDate] = useState(null);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [error, setError] = useState(null);

  const abbreviations = {
    DA: "Defs since 2017",
    DB: "Defs since 2015",
    DC: "Defs pre 2015",
    PA: "Probs since 2017",
    PB: "Probs since 2015",
    PC: "Probs pre 2015",
    Lib: "Lib Dem Canv Analysis",
    CoL: "Living with a Lib Dem",
    Ema: "Has email address",
    LaS: "Labour Squeeze",
    GrS: "Green squeeze",
    EU: "EU citizen",
    LLa: "Leans Labour",
    GrO: "Green other",
    LaO: "Labour Others",
    LHL: "Leans Hard Labour",
    LCo: "Leans Con",
    NaS: "Nationalist Squeeze",
    CoS: "Conservative squeeze",
    SNo: "Scottish No voter",
    Wel: "Welsh speaker",
    LSN: "Leans Scots Nat",
    pEU: "Pro EU",
    EE: "Everyone else",
    aEU: "Anti EU",
    LHC: "Leans Hard Conservative",
    NaO: "Nationalist other",
    CoO: "Conservative other",
  };

  useEffect(() => {
    async function getShuttleworthResponseData() {
      setDataLoaded(false);
      setError(null);

      try {
        setShuttleworthResponseData(null);
        let rawdata = await api.seatData.shuttleworthResponse(areaID);
        let data = getShuttleworthGraphingData(rawdata);
        setReferenceDate(data.referenceDate);
        setShuttleworthResponseData(data.graphingData);
        setDataLoaded(true);
      } catch (e) {
        setError(e);
        setDataLoaded(true);
      }
    }

    getShuttleworthResponseData();
  }, [areaID]);

  function getTitleDate() {
    if (!referenceDate) return "-";
    return new Date(referenceDate)
      .toUTCString()
      .split(", ")[1]
      .split(/[0-9]{2}:/)[0]
      .trim();
  }

  return (
    <div>
      <Helmet>
        <title>{areaName ?? ""} - Shuttleworth</title>
      </Helmet>
      <h3 className="title">Shuttleworth response (vs. {getTitleDate()})</h3>
      <Container fluid style={{ position: "relative" }}>
        <Dimmer dataLoaded={dataLoaded} error={error}>
          <ComposedChart width={1000} height={500} data={shuttleworthResponseData}>
            <CartesianGrid vertical={false} />
            <XAxis
              dataKey="category"
              onMouseEnter={(e) => setFullName(abbreviations[e.value])}
              onMouseLeave={() =>
                setTimeout(() => {
                  setFullName(null);
                }, 500)
              }
            />
            <YAxis
              label={{
                value: "Response (%)",
                angle: -90,
                position: "insideLeft",
                style: {
                  textAnchor: "middle",
                },
              }}
              axisLine={false}
            />
            <YAxis
              yAxisId="shuttleworthSize"
              orientation="right"
              label={{
                value: "Shuttleworth Size (1000s)",
                angle: 90,
                position: "insideRight",
                style: {
                  textAnchor: "middle",
                },
              }}
              tickFormatter={(value) => value / 1000}
              axisLine={false}
            />
            <Bar dataKey="dPercent" stackId="a" fill="#FAA61A" isAnimationActive={false} />
            <Bar dataKey="pPercent" stackId="a" fill="#FEDD97" isAnimationActive={false} />
            <Bar dataKey="othersPercent" stackId="a" fill="#B0B0B0" isAnimationActive={false} />
            <Bar dataKey="noData" stackId="a" fill="#edebeb" isAnimationActive={false} />
            <Line
              yAxisId="shuttleworthSize"
              dataKey="target"
              type="monotone"
              dot={false}
              stroke="black"
              isAnimationActive={false}
              strokeWidth={2}
            />
            <Line
              yAxisId="shuttleworthSize"
              dataKey="cumulativeSize"
              type="monotone"
              dot={false}
              stroke="#0000A0"
              isAnimationActive={false}
              strokeWidth={4}
            />
            <Line
              yAxisId="shuttleworthSize"
              dataKey="cumulativeLDs"
              type="monotone"
              dot={false}
              stroke="#0000C0"
              isAnimationActive={false}
              strokeWidth={3}
            />
          </ComposedChart>
        </Dimmer>
      </Container>
      {fullName}
    </div>
  );
}
