import React, { useContext, useEffect, useState } from "react";
import { useParams, NavLink, Routes, Route, Link, generatePath } from "react-router-dom";
import { DropdownHeader, Menu, Header } from "semantic-ui-react";
import { Helmet } from "react-helmet-async";
import { canAccess } from "../../logic/userPermissionsHandler";
import { UserContext } from "../shared/UserContext";

import api from "../../logic/apiHandler";

import CanvassCharts from "./CanvassCharts";
import PollingDayScreen from "./PollingDayScreen";
import ScorePerformanceScreen from "./ScorePerformanceScreen";
import ScoreProfileScreen from "./ScoreProfileScreen";
import ChesterCharts from "./ChesterCharts";
import ShuttleworthResponseGraph from "./ShuttleworthResponseGraph";
import SwitchAnalysisScreen from "./SwitchAnalysisScreen";
import PollingDistrictCodesTable from "./PollingDistrictCodesTable";
import DropdownSearchMenu from "../shared/DropdownSearchMenu";
import ChildrenConstituencies from "./ChildrenConstituencies";
import SeatLevelLabel from "../shared/SeatLevelLabel";
import SeatTypeLabel from "./SeatTypeLabel";
import GESummaryTable from "../shared/GESummaryTable";
import QuestionsListTable from "./QuestionsListTable";
import ActivistCodesScreen from "./ActivistCodesScreen";
import WalkPDSummaryTable from "./WalkPDSummaryTable";
import PDWalksTable from "./PDWalksTable";
import WalkSummarisationTable from "./WalkSummarisationTable";
import LocalElections from "./LocalElections";
import VoteHistory from "./VoteHistory";
import ParentVoteHistory from "./ParentVoteHistory";
import ErrorBoundary from "../shared/ErrorBoundary";
import PVUploadRecency from "./PVUploadRecency";

export default function SeatData() {
  let { areaID } = useParams();
  const [areaName, setAreaName] = useState(null);
  const [areaLabel, setAreaLabel] = useState("");
  const user = useContext(UserContext);
  const [areaType, setAreaType] = useState("");
  const [isParentField, setIsParentField] = useState(false);
  const params = useParams();

  useEffect(() => {
    async function getSeatInfo() {
      setAreaName(null);
      setAreaLabel("");
      try {
        let data = await api.seatData.info(areaID);
        const field = data.fieldcode;
        if (field === "CR" || field === "ER" || field === "DevR" || field === "DBU") {
          setIsParentField(true);
        } else {
          setIsParentField(false);
        }
        setAreaLabel(data.label ? data.label : "");
        setAreaName(data.name);
        setAreaType(field);
      } catch (e) {
        console.log("e", e);
      }
    }

    getSeatInfo();
  }, [areaID]);

  function formatDropdownOptions(options) {
    let menuOptions = [];

    Object.keys(options ?? []).map((areaType) => {
      if (areaType !== "westminster") {
        let areaName = areaType.charAt(0).toUpperCase() + areaType.slice(1);
        areaName = areaName.match(/[A-Z][a-z]+|[0-9]+/g).join(" ");
        if (areaName === "Westminster 2024") {
          areaName = "Westminster";
        }
        menuOptions.push({
          text: areaName,
          value: areaName,
          content: <DropdownHeader content={areaName} />,
          disabled: true,
        });

        const areaTypeAbbreviation = getAreaTypeAbbreviation(areaType);
        for (let constituency of options[areaType]) {
          const tag = constituency.label ? (
            <SeatLevelLabel label={constituency.label.toLowerCase()} floatright />
          ) : null;
          menuOptions.push({
            as: Link,
            key: `${areaType}:${constituency.code}`,
            text: `${constituency.name} - ${areaTypeAbbreviation}`,
            value: generatePath("/seatData/:areaID/*", { ...params, areaID: constituency.code }),
            to: generatePath("/seatData/:areaID/*", { ...params, areaID: constituency.code }),
            label: tag,
          });
        }
      }
    });

    return menuOptions;
  }

  function getAreaTypeAbbreviation(areaType) {
    switch (areaType) {
      case "westminster2024":
        return "W24";
      case "localAuthority":
        return "LA";
      case "european":
        return "EU";
      default:
        return;
    }
  }

  return (
    <div>
      <Helmet>
        <title>Seat Data - {areaName ?? areaID}</title>
      </Helmet>
      <Header as="h2">
        Seat data - {areaName ?? areaID}
        {areaLabel !== "" ? <SeatLevelLabel label={areaLabel} /> : null}
        {areaType !== "" ? <SeatTypeLabel type={areaType} /> : null}
      </Header>
      <Menu secondary size="small" borderless pointing>
        {isParentField ? (
          <>
            <Menu.Item as={NavLink} to="./" name="child-list">
              Child areas
            </Menu.Item>
            {canAccess(user, "basicCanvassing") ? (
              <Menu.Item as={NavLink} to="./walkAreaSummary" name="walk-area-summary">
                Walk Area Summary
              </Menu.Item>
            ) : null}
            {canAccess(user, "advCanvassing") ? (
              <Menu.Item as={NavLink} to="./parentVoteHistory" name="parent-vote-history">
                Parent Vote History
              </Menu.Item>
            ) : (
              <></>
            )}
          </>
        ) : (
          <>
            {canAccess(user, "basicCanvassing") ? (
              <Menu.Item as={NavLink} to="./canvassing" name="canvassing">
                Canvassing
              </Menu.Item>
            ) : (
              <></>
            )}
            {canAccess(user, "basicCanvassing") ? (
              <Menu.Item as={NavLink} to="./pollingDay" name="polling-day">
                Polling Day
              </Menu.Item>
            ) : (
              <></>
            )}
            {canAccess(user, "scoreData") ? (
              <Menu.Item as={NavLink} to="./scoreProfile" name="score-profile-charts">
                Score profiles
              </Menu.Item>
            ) : (
              <></>
            )}
            {canAccess(user, "scoreData") ? (
              <Menu.Item as={NavLink} to="./scorePerformance" name="score-performance-chester-charts">
                Score performance
              </Menu.Item>
            ) : (
              <></>
            )}
            {canAccess(user, "basicCanvassing") ? (
              <Menu.Item as={NavLink} to="./shuttleworth" name="shuttleworth">
                Shuttleworth
              </Menu.Item>
            ) : (
              <></>
            )}
            {canAccess(user, "basicCanvassing") ? (
              <Menu.Item as={NavLink} to="./switchAnalysis" name="switch-analysis">
                Switch analysis
              </Menu.Item>
            ) : (
              <></>
            )}
            {areaType === "W2024" && canAccess(user, "basicCanvassing") ? (
              <Menu.Item as={NavLink} to="./geCanvass" name="ge-canvassing">
                GE canvassing
              </Menu.Item>
            ) : (
              <></>
            )}
            {(areaType === "DBUWR" || areaType === "CR" || areaType === "CEDR") &&
            canAccess(user, "basicCanvassing") ? (
              <Menu.Item as={NavLink} to="./leCanvass" name="le-canvassing">
                LE canvassing
              </Menu.Item>
            ) : (
              <></>
            )}
            {canAccess(user, "advCanvassing") ? (
              <Menu.Item as={NavLink} to="./pdCodes" name="pd codes">
                PD codes
              </Menu.Item>
            ) : (
              <></>
            )}
            {canAccess(user, "privateCharlie") ? (
              <Menu.Item as={NavLink} to="./surveyQuestions" name="survey-questions">
                Survey Questions
              </Menu.Item>
            ) : (
              <></>
            )}
            {canAccess(user, "privateCharlie") ? (
              <Menu.Item as={NavLink} to="./activistCodes" name="activist-codes">
                Activist Codes
              </Menu.Item>
            ) : (
              <></>
            )}
            {canAccess(user, "advCanvassing") ? (
              <Menu.Item as={NavLink} to="./walkSummary" name="walk-summary">
                Walk Summary
              </Menu.Item>
            ) : (
              <></>
            )}
            {canAccess(user, "advCanvassing") ? (
              <Menu.Item as={NavLink} to="./voteHistory" name="vote-history">
                Vote History
              </Menu.Item>
            ) : (
              <></>
            )}
            {canAccess(user, "advCanvassing") ? (
              <Menu.Item as={NavLink} to="./pvUploadRecency" name="pv-upload-recency">
                PV Upload Recency
              </Menu.Item>
            ) : (
              <></>
            )}

            <Menu.Item>
              <DropdownSearchMenu options={formatDropdownOptions(user.allowedConstituencies)} />
            </Menu.Item>
          </>
        )}
      </Menu>
      <ErrorBoundary fallback={<p>Oops!</p>}>
        <Routes>
          <Route
            path="/"
            element={isParentField ? <ChildrenConstituencies /> : <div>Hello - you're at - {areaName ?? areaID}</div>}
          />
          <Route path="/canvassing" element={<CanvassCharts areaName={areaName} />} />
          <Route path="/pollingDay" element={<PollingDayScreen areaName={areaName} />} />
          <Route path="/scoreProfile" element={<ScoreProfileScreen areaName={areaName} />} />
          <Route path="/scorePerformance" element={<ScorePerformanceScreen areaName={areaName} />} />
          <Route path="/geCanvass" element={<ChesterCharts areaName={areaName} />} />
          <Route path="/leCanvass" element={<LocalElections areaName={areaName} />} />
          <Route path="/shuttleworth" element={<ShuttleworthResponseGraph areaName={areaName} />} />
          <Route path="/switchAnalysis" element={<SwitchAnalysisScreen areaName={areaName} />} />
          <Route path="/pdCodes" element={<PollingDistrictCodesTable areaName={areaName} />} />
          <Route path="/geSummary" element={<GESummaryTable areaName={areaName} />} />
          <Route path="/surveyQuestions" element={<QuestionsListTable areaName={areaName} />} />
          <Route path="/activistCodes" element={<ActivistCodesScreen areaName={areaName} />} />
          <Route path="/walkSummary" element={<WalkPDSummaryTable areaName={areaName} />} />
          <Route path="/walkSummary/:regArea/:pd" element={<PDWalksTable areaName={areaName} />} />
          <Route path="/walkAreaSummary" element={<WalkSummarisationTable areaName={areaName} areaType={areaType} />} />
          <Route path="/voteHistory" element={<VoteHistory areaName={areaName} />} />
          <Route path="/parentVoteHistory" element={<ParentVoteHistory areaName={areaName} />} />
          <Route path="/pvUploadRecency" element={<PVUploadRecency />} />

          <Route
            path="*"
            element={
              <div>
                <h1>404</h1>You are nowhere
              </div>
            }
          />
        </Routes>
      </ErrorBoundary>
    </div>
  );
}
