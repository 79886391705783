import React, { useState } from "react";
import { Button, Form, FormField, FormGroup, Radio } from "semantic-ui-react";

export default function SwitchAnalysisForm({ setDisplayedOptions, updateSwitchAnalysisData }) {
  const [question, setQuestion] = useState("mpid");
  const [duration, setDuration] = useState("GEcampaign");
  const [comparison, setComparison] = useState("2019GE");

  async function handleSubmit() {
    setDisplayedOptions(
      `${getLabel(question, formOptions.question.values)}, ${getLabel(
        duration,
        formOptions.duration.values
      )}, ${getLabel(comparison, formOptions.comparison.values)}`
    );
    try {
      await updateSwitchAnalysisData(comparison, duration);
    } catch (e) {
      console.log("e", e);
    }
  }

  const formOptions = {
    question: {
      method: setQuestion,
      values: [{ label: "MPID", value: "mpid" }],
    },
    duration: {
      type: duration,
      method: setDuration,
      values: [
        { label: "7 days", value: "7days" },
        { label: "2025", value: "2025" },
      ],
    },
    comparison: {
      type: comparison,
      method: setComparison,
      values: [
        { label: "2024GE", value: "2024GE" },
        { label: "2021", value: "2021L" },
        { label: "2023", value: "2023L" },
        { label: "2024", value: "2024L" },
      ],
    },
  };

  function renderRadioOptions(options) {
    return options.values.map((option) => {
      return (
        <Radio
          key={option.value}
          label={option.label}
          name={option.value}
          value={option.value}
          checked={options.type === option.value}
          onChange={() => options.method(option.value)}
          style={{ paddingLeft: "15px" }}
        />
      );
    });
  }

  function getLabel(value, options) {
    return options.filter((option) => option.value === value)[0].label;
  }

  return (
    <Form>
      <FormField inline>
        <b>Question:</b>
        <Radio
          label="MPID"
          name="mpid"
          value="mpid"
          checked={question === "mpid"}
          onClick={() => setQuestion("mpid")}
        />
      </FormField>
      <FormGroup inline>
        <b>Set duration:</b>
        {renderRadioOptions(formOptions.duration)}
      </FormGroup>
      <FormField inline>
        <b> Compare to:</b>
        {renderRadioOptions(formOptions.comparison)}
      </FormField>
      <Button type="submit" onClick={handleSubmit}>
        Update
      </Button>
    </Form>
  );
}
