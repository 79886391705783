import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, ResponsiveContainer } from "recharts";
import Dimmer from "../shared/Dimmer";
import { getScorePerformanceGraphing } from "../../logic/scorePerformanceDataProcessor";
import { Container } from "semantic-ui-react";
import api from "../../logic/apiHandler";

export default function ScorePerformanceChesterCharts({ roll = 3, scoreName, title, isComparison = false }) {
  const [dataLoaded, setDataLoaded] = useState(false);
  const [error, setError] = useState(null);
  const [scorePerformanceData, setScorePerformanceData] = useState(null);
  let { areaID } = useParams();
  const [totalCount, setTotalCount] = useState(0);

  const colourMap = {
    cLD: "#FAA61A",
    cLab: "#E60B0B",
    cCon: "#2C0BE6",
    cSNP: "#FFFF00",
    cPla: "#3D6B48",
    cUKI: "#18B9D1",
    cGre: "#38CB07",
    cInd: "#536663",
    cBNP: "#202020",
  };

  useEffect(() => {
    async function getScorePerformanceData() {
      setDataLoaded(false);
      setError(null);

      try {
        let data = await api.seatData.scorePerformance(areaID, scoreName);
        data = await getScorePerformanceGraphing(data, roll, isComparison);
        setScorePerformanceData(data);
        setTotalCount(Math.round(data.reduce((total, curr) => total + (curr?.count ?? 0), 0)));
        setDataLoaded(true);
      } catch (e) {
        setError(e);
        setDataLoaded(true);
      }
    }

    getScorePerformanceData();
  }, [roll, areaID, scoreName, isComparison]);

  function renderLines() {
    return Object.keys(colourMap).map((party) => (
      <Line
        yAxisId="percent"
        type="monotone"
        unit="%"
        dataKey={party}
        stroke={colourMap[party]}
        dot={false}
        key={party}
        isAnimationActive={false}
      />
    ));
  }

  return (
    <div>
      <h3 className="title">
        {title} Score Performance ({totalCount})
      </h3>
      <Container>
        <Dimmer dataLoaded={dataLoaded} error={error}>
          <ResponsiveContainer aspect={1.6} minWidth={400}>
            <LineChart data={scorePerformanceData}>
              <CartesianGrid strokeDasharray="3 7" />
              {isComparison ? (
                <XAxis dataKey="score" tick={true} type="number" ticks={[-100, -75, -50, -25, 0, 25, 50, 75, 100]} />
              ) : (
                <XAxis dataKey="score" tick={true} type="number" ticks={[0, 20, 40, 60, 80, 100]} />
              )}
              <YAxis
                yAxisId="percent"
                label={{ value: "percent", angle: -90, position: "insideLeft", style: { textAnchor: "middle" } }}
                domain={[0, 100]}
                tickCount={5}
                allowDataOverflow={isComparison} // or always?
              />
              <YAxis
                yAxisId="volume"
                orientation="right"
                label={{
                  value: "volume",
                  angle: 90,
                  position: "insideRight",
                  style: { textAnchor: "middle" },
                }}
                domain={[0, 500]}
                ticks={[0, 250, 500]}
                allowDataOverflow={true}
              />
              {renderLines()}
              <Line
                yAxisId="percent"
                type="monotone"
                stroke="grey"
                opacity="30%"
                dataKey="XY"
                dot={false}
                isAnimationActive={false}
              />
              <Line
                yAxisId="volume"
                type="monotone"
                dataKey="count"
                stroke="black"
                strokeDasharray="4"
                opacity="60%"
                dot={false}
                key="count"
                isAnimationActive={false}
              />
            </LineChart>
          </ResponsiveContainer>
        </Dimmer>
      </Container>
    </div>
  );
}
