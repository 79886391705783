import React, { useEffect, useState } from "react";
import {
  Button,
  ButtonGroup,
  Checkbox,
  Form,
  FormGroup,
  FormButton,
  FormField,
  FormInput,
  Input,
  Grid,
  GridColumn,
  GridRow,
  Header,
  List,
  ListItem,
  Message,
} from "semantic-ui-react";
import api from "../../logic/apiHandler";
import { useNavigate, useParams } from "react-router-dom";
import UserDistrictAccessTable from "./UserDistrictAccessTable";
import UserDistrictList from "./UserDistrictList";
import Dimmer from "../shared/Dimmer";

export default function EditUserForm() {
  const { userID } = useParams();
  const navigate = useNavigate();
  const [type, setType] = useState(null);
  const [userSuspended, setUserSuspended] = useState(false);
  const [availableRoles, setAvailableRoles] = useState([]);
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [hasNationalAccess, setHasNationalAccess] = useState(false);
  const [selectedDistricts, setSelectedDistricts] = useState([]);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState({});
  const [allDistricts, setAllDistricts] = useState({});

  useEffect(() => {
    (async () => {
      setError(false);
      setLoading(true);

      try {
        /** get generic data */
        const roles = await api.userManage.getUserRoles();
        setAvailableRoles(roles.filter((role) => !role.restricted));
        setAllDistricts(await api.userData.getAllAreas());

        /** get user specific data */
        const res = await api.userManage.userData(userID);
        setUserData(res);
        setUserSuspended(!res.active);
        setSelectedDistricts(res.districts);
        setSelectedRoles(res.roles.map((role) => role.roleID));
        setLoading(false);
      } catch (e) {
        setError(true);
      }
    })();
  }, [userID]);

  function renderRoles() {
    return availableRoles.map((role) => {
      return (
        <ListItem key={role.roleID}>
          <Checkbox
            label={role.roleName}
            value={role.roleID}
            onChange={(e, d) => updateSelectedRoles(d)}
            defaultChecked={selectedRoles.includes(role.roleID)}
          />
        </ListItem>
      );
    });
  }

  function updateSelectedRoles(d) {
    if (d.checked) {
      setSelectedRoles([...selectedRoles, d.value]);
    } else if (!d.checked) {
      setSelectedRoles((prev) => prev.filter((role) => role !== d.value));
    }
  }

  async function handleUpdate() {
    setError(false);

    try {
      await api.userManage.updateUserStatus(userData.userID, { status: !userSuspended });
      await api.userManage.editUserRoles(userData.userID, { roles: selectedRoles });
      let districts = hasNationalAccess ? [] : selectedDistricts.map((district) => district.code);
      await api.userManage.editUserDistricts(userData.userID, { nationalAccess: hasNationalAccess, districts });
      navigate("/users");
    } catch (e) {
      console.log("e", e);
      setError(true);
    }
  }

  return (
    <div>
      <Header as="h2">Edit User</Header>
      <Dimmer dataLoaded={!loading}>
        <Form>
          <Grid stackable>
            <GridRow style={{ marginTop: "10px" }}>
              <GridColumn>
                <FormGroup widths="equal">
                  <FormField
                    label="Forename"
                    control={Input}
                    disabled
                    placeholder="Forename"
                    defaultValue={userData.forename}
                    readOnly
                  />
                  <FormInput
                    label="Surname"
                    control={Input}
                    disabled
                    placeholder="Surname"
                    defaultValue={userData.surname}
                    readOnly
                  />
                  <FormInput
                    label="Email"
                    control={Input}
                    disabled
                    placeholder="Email"
                    defaultValue={userData.email}
                    readOnly
                  />
                </FormGroup>
                <FormGroup>
                  <Checkbox
                    toggle
                    label="Suspend user"
                    checked={userSuspended}
                    onChange={(e, d) => setUserSuspended(d.checked)}
                    style={{ padding: "20px" }}
                  />
                </FormGroup>
                <ButtonGroup>
                  <Button
                    style={{ backgroundColor: type === "roles" ? "#FAA61A" : "" }}
                    value="roles"
                    onClick={() => setType("roles")}
                  >
                    Edit Roles
                  </Button>
                  <Button
                    style={{ backgroundColor: type === "districts" ? "#FAA61A" : "" }}
                    value="districts"
                    onClick={() => setType("districts")}
                  >
                    Edit Districts
                  </Button>
                  <Button
                    style={{ backgroundColor: type === "X" ? "#FAA61A" : "" }}
                    value="districts"
                    onClick={() => setType("x")}
                  >
                    X
                  </Button>
                </ButtonGroup>
              </GridColumn>
            </GridRow>
            <GridRow columns={3} doubling stackable>
              <GridColumn floated="left" width={4}>
                {type === "roles" ? (
                  <List>{renderRoles()}</List>
                ) : type === "districts" ? (
                  <>
                    <GridRow>
                      <GridColumn>
                        <Checkbox
                          label="Give National Access"
                          defaultChecked={userData.hasNationalPermissions}
                          onChange={(e, d) => setHasNationalAccess(d.checked)}
                          style={{ margin: "10px" }}
                        />
                      </GridColumn>
                    </GridRow>
                    <GridRow stackable>
                      <GridColumn>
                        <UserDistrictAccessTable
                          setSelectedDistricts={setSelectedDistricts}
                          userDistricts={selectedDistricts}
                        />
                      </GridColumn>
                      <GridColumn>
                        <UserDistrictList
                          allDistricts={allDistricts}
                          setSelectedDistricts={setSelectedDistricts}
                          userDistricts={selectedDistricts}
                        />
                      </GridColumn>
                    </GridRow>
                  </>
                ) : (
                  <></>
                )}
              </GridColumn>
            </GridRow>
            <GridRow columns={2} centered>
              {error ? (
                <Message error header={"Cannot complete your request at this time; please try again later"} />
              ) : (
                <></>
              )}
            </GridRow>
            <GridRow>
              <ButtonGroup>
                <FormButton style={{ backgroundColor: "#FAA61A" }} onClick={() => handleUpdate()}>
                  Update User
                </FormButton>
                <FormButton onClick={() => navigate(-1)}>Cancel</FormButton>
              </ButtonGroup>
            </GridRow>
          </Grid>
        </Form>
      </Dimmer>
    </div>
  );
}
