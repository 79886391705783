import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import api from "../../logic/apiHandler";
import { parseData } from "../../logic/pollingDataProcessor";
import PollingDayTable from "./PollingDayTable";
import { Helmet } from "react-helmet-async";
import { UserContext } from "../shared/UserContext";
import { Button, ButtonGroup, Container } from "semantic-ui-react";
import { canAccess } from "../../logic/userPermissionsHandler";
import Dimmer from "../shared/Dimmer";

export default function PollingDayScreen({ areaName }) {
  const [polldayData, setPolldayData] = useState([]);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [error, setError] = useState(null);
  const [dataSet, setDataSet] = useState("now");
  const user = useContext(UserContext);
  let { areaID } = useParams();

  useEffect(() => {
    async function getPolldayData() {
      setDataLoaded(false);
      setError(null);

      try {
        let data = await api.seatData.pollingDay(areaID, dataSet);
        data = parseData(data);
        setPolldayData(data);
        setDataLoaded(true);
      } catch (e) {
        setError(e);
        setDataLoaded(true);
      }
    }

    getPolldayData();
  }, [areaID, dataSet]);

  return (
    <div style={{ position: "relative" }}>
      <Helmet>
        <title>{areaName ?? ""} - Polling Day</title>
      </Helmet>
      {canAccess(user, "privateCharlie") ? (
        <div style={{ position: "relative" }}>
          <ButtonGroup>
            <Button key="now" onClick={() => setDataSet("now")} color={dataSet === "now" ? "yellow" : null}>
              Now
            </Button>
            <Button key="20230505" onClick={() => setDataSet(20230505)} color={dataSet === 20230505 ? "yellow" : null}>
              2023 LE
            </Button>
            <Button key="20240503" onClick={() => setDataSet(20240503)} color={dataSet === 20240503 ? "yellow" : null}>
              2024 LE
            </Button>
            <Button key="20240705" onClick={() => setDataSet(20240705)} color={dataSet === 20240705 ? "yellow" : null}>
              2024 GE
            </Button>
          </ButtonGroup>
        </div>
      ) : (
        <></>
      )}

      <Container fluid style={{ position: "relative", minHeight: "200px" }}>
        <Dimmer dataLoaded={dataLoaded} error={error}>
          <PollingDayTable data={polldayData} />
        </Dimmer>
      </Container>
    </div>
  );
}
