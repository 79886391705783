import React, { useEffect, useState } from "react";
import { Grid, GridRow, GridColumn, Header, Label, StatisticValue, StatisticLabel, Statistic } from "semantic-ui-react";
import { Helmet } from "react-helmet-async";
import { BarChart, Bar, XAxis, YAxis, ReferenceLine, ResponsiveContainer, Line, LineChart } from "recharts";
import { useParams } from "react-router-dom";
import api from "../../logic/apiHandler";
import Dimmer from "../shared/Dimmer";

export default function EmailUsage() {
  const [barChartData, setBarChartData] = useState([]);
  const [lineChartData, setLineChartData] = useState([]); // names!!
  const [emailStats, setEmailStats] = useState({});
  const [dataLoaded, setDataLoaded] = useState(false);
  const [error, setError] = useState(null);
  const { committeeID } = useParams();

  useEffect(() => {
    (async () => {
      setDataLoaded(false);
      setError(null);

      try {
        const res = await api.committeeData.emailUse(committeeID);
        getBarChartData(res);
        getEmailStats(res);
        let subsOverTime = await api.committeeData.emailSubsOverTime(committeeID);
        getLineChartData(subsOverTime);
        setDataLoaded(true);
      } catch (e) {
        setError(e);
        setDataLoaded(true);
      }
    })();
  }, [committeeID]);

  function getBarChartData(data) {
    let graphingData = [];
    for (let i = 0; i < 14; i++) {
      let month = data.lastContacted.find((row) => row.months === i);
      if (month) {
        graphingData.push({
          months: i + 1,
          longSubscribed: month.longSubscribed,
          recentlySubscribed: month.recentlySubscribed,
        });
      } else {
        graphingData.push({ months: i + 1, longSubscribed: 0, recentlySubscribed: 0 });
      }
    }

    const monthsFifteenPlus = data.lastContacted.filter((row) => row.months >= 14);
    graphingData.push({
      months: 15,
      longSubscribed: monthsFifteenPlus.reduce((acc, curr) => acc + parseInt(curr.longSubscribed), 0),
      recentlySubscribed: monthsFifteenPlus.reduce((acc, curr) => acc + parseInt(curr.recentlySubscribed), 0),
    });

    const neverContacted = data.lastContacted.find((row) => row.months === null);
    graphingData.push({ ...neverContacted, months: 16 });

    graphingData = graphingData.sort((a, b) => {
      return a.months - b.months;
    });
    setBarChartData(graphingData);
  }

  function getLineChartData(data) {
    let maxDate = data.reduce((a, b) => (a.date > b.date ? a : b)).date;
    let minDate = new Date(data.reduce((a, b) => (a.date < b.date ? a : b)).date);

    let startDate = new Date(maxDate);
    startDate.setMonth(startDate.getMonth() - 11);
    while (startDate < minDate) {
      data.push({ date: startDate.toISOString() });
      startDate.setDate(startDate.getDate() + 1);
    }

    data = data.sort((a, b) => {
      return new Date(a.date) - new Date(b.date);
    });
    setLineChartData(data);
  }

  function getEmailStats(res) {
    let totalSubs = 0;
    totalSubs += res.lastContacted.map((i) => i.longSubscribed + i.recentlySubscribed).reduce((a, b) => a + b, 0);
    setEmailStats({
      totalSubs,
      neverContacted: res.lastContacted
        .map((i) => (i.months === null ? i.longSubscribed + i.recentlySubscribed : null))
        .reduce((a, b) => a + b, 0),
      outOfRetention: res.lastContacted
        .map((i) => (i.months > 12 ? i.longSubscribed + i.recentlySubscribed : null))
        .reduce((a, b) => a + b, 0),
      nearlyOutOfRetention: res.lastContacted
        .map((i) => (i.months > 9 && i.months <= 12 ? i.longSubscribed + i.recentlySubscribed : null))
        .reduce((a, b) => a + b, 0),
    });
  }

  const CustomTick = ({ x, y, payload }) => {
    let value = payload.value;
    if (value === 0 || value === 17) {
      value = "";
    } else if (value === 15) {
      value = "15+";
    }

    if (value === 16) {
      return (
        <g>
          <g transform={`translate(${x},${y})`} className="weird-tick">
            <text x={0} y={0} dy={16}>
              <tspan textAnchor="middle" x="0">
                Never
              </tspan>
              <tspan textAnchor="middle" x="0" dy="12">
                Been
              </tspan>
              <tspan textAnchor="middle" x="0" dy="12">
                Contacted
              </tspan>
            </text>
          </g>
          <g transform={`translate(${x},${y})`} className="weirder-tick">
            <text x={0} y={0} dy={16}>
              <tspan textAnchor="middle" x="0">
                NBC
              </tspan>
            </text>
          </g>
        </g>
      );
    } else {
      return (
        <g transform={`translate(${x},${y})`}>
          <text x={0} y={0} dy={16} textAnchor="middle">
            {value}
          </text>
        </g>
      );
    }
  };

  function formatTick(tickValue) {
    console.log("tickValue", tickValue);
    let month = new Date(tickValue).getMonth();
    let months = ["J", "F", "M", "A", "M", "J", "J", "A", "S", "O", "N", "D"];
    return months[month];
  }

  function getDateTicks(data) {
    if (data.length) {
      let maxDate = new Date(data.reduce((a, b) => (a.date > b.date ? a : b)).date);
      let minDate = new Date(maxDate);
      minDate.setMonth(minDate.getMonth() - 11);
      minDate.setDate(1);

      let startDate = new Date(minDate);
      let startMonth = startDate.getMonth() + 1 < 10 ? `0${startDate.getMonth() + 1}` : startDate.getMonth() + 1;
      let monthTicks = [`${startDate.getFullYear()}-${startMonth}-01T00:00:00.000Z`];

      while (startDate <= maxDate) {
        let year = startDate.getFullYear();
        let month = startDate.getMonth();
        let newDate = new Date(year, month + 1, 1);

        let newMonth = newDate.getMonth() + 1 < 10 ? `0${newDate.getMonth() + 1}` : newDate.getMonth() + 1;
        monthTicks.push(`${newDate.getFullYear()}-${newMonth}-01T00:00:00.000Z`);
        startDate = newDate;
      }

      console.log("monthTicks", monthTicks);
      return monthTicks;
    }
  }

  function renderLegend() {
    let lineDetails = [
      { dataKey: "VANIDsWithSubscribedEmailCount", colour: "#FAA61A" },
      { dataKey: "CampaignNewsSubscribedCount", colour: "red" },
      { dataKey: "LocalNewsSubscribedCount", colour: "black" },
    ];

    return (
      <div style={{ display: "flex", flexFlow: "row wrap", alignItems: "center", paddingLeft: "50px" }}>
        {lineDetails.map((line) => {
          let name = line.dataKey.split(/(?<![A-Z])(?=[A-Z])/).join(" ");
          name = name.charAt(0).toUpperCase() + name.slice(1);

          return (
            <div
              key={line.dataKey}
              style={{ marginRight: "10px", display: "flex", alignItems: "center", paddingBottom: "5px" }}
            >
              <Label size="tiny" circular style={{ backgroundColor: line.colour }} />
              <span style={{ marginLeft: "5px" }}>{name}</span>
            </div>
          );
        })}
      </div>
    );
  }

  return (
    <div>
      <Helmet>
        <title>Email Usage</title>
      </Helmet>
      <Dimmer error={error} dataLoaded={dataLoaded}>
        <div>
          <Header as="h2">Email Usage</Header>
          <Grid>
            <GridRow>
              <GridColumn width={4}>
                <Statistic size="tiny">
                  <StatisticValue>{emailStats.totalSubs}</StatisticValue>
                  <StatisticLabel>total subscribed email addresses</StatisticLabel>
                </Statistic>
              </GridColumn>
              <GridColumn width={4}>
                <Statistic size="tiny">
                  <StatisticValue>{emailStats.neverContacted}</StatisticValue>
                  <StatisticLabel>never been emailed</StatisticLabel>
                </Statistic>
              </GridColumn>
              <GridColumn width={4}>
                <Statistic size="tiny">
                  <StatisticValue>{emailStats.nearlyOutOfRetention}</StatisticValue>
                  <StatisticLabel>nearly out of retention</StatisticLabel>
                </Statistic>
              </GridColumn>
              <GridColumn width={4}>
                <Statistic size="tiny">
                  <StatisticValue>{emailStats.outOfRetention}</StatisticValue>
                  <StatisticLabel>out of retention</StatisticLabel>
                </Statistic>
              </GridColumn>
            </GridRow>
          </Grid>
          <Grid doubling stackable>
            <GridRow columns={2} verticalAlign="bottom">
              <GridColumn>
                <ResponsiveContainer aspect={2} minWidth="45%" minHeight={350} style={{ marginTop: "40px" }}>
                  <BarChart data={barChartData}>
                    <XAxis
                      dataKey="months"
                      type="number"
                      interval={0}
                      domain={[0, 17]}
                      tick={<CustomTick />}
                      ticks={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17]}
                      height={60}
                      label={{ value: "Months", offset: 0, position: "insideBottom" }}
                    />
                    <YAxis
                      label={{
                        value: "# of email addresses",
                        angle: -90,
                        position: "insideLeft",
                        style: { textAnchor: "middle" },
                      }}
                    />
                    <Bar dataKey="longSubscribed" stackId="a" fill="#FAA61A" />
                    <Bar dataKey="recentlySubscribed" stackId="a" fill="#000000" />
                    <ReferenceLine x={9.5} isFront={false} stroke="red" strokeDasharray="3 3" />
                    <ReferenceLine x={12.5} isFront={false} stroke="red" strokeDasharray="3 3" />
                  </BarChart>
                </ResponsiveContainer>
              </GridColumn>
              <GridColumn>
                <ResponsiveContainer aspect={2} minWidth="45%" minHeight={350} style={{ marginTop: "40px" }}>
                  <LineChart data={lineChartData}>
                    <XAxis
                      dataKey="date"
                      tick={true}
                      tickFormatter={formatTick}
                      ticks={getDateTicks(lineChartData)}
                      padding={{ right: 20 }}
                      height={60}
                    />
                    <YAxis
                      label={{
                        value: "Email Subscription Rates",
                        angle: -90,
                        position: "insideLeft",
                        style: { textAnchor: "middle" },
                      }}
                    />
                    <Line
                      dataKey="VANIDsWithSubscribedEmailCount"
                      dot={false}
                      isAnimationActive={false}
                      strokeWidth={4}
                      stroke="#FAA61A"
                    />
                    <Line dataKey="CampaignNewsSubscribedCount" dot={false} isAnimationActive={false} stroke="red" />
                    <Line dataKey="LocalNewsSubscribedCount" dot={false} isAnimationActive={false} stroke="black" />
                  </LineChart>
                </ResponsiveContainer>
              </GridColumn>
            </GridRow>
            <GridRow columns={2} verticalAlign="bottom">
              <GridColumn />
              <GridColumn>{renderLegend()}</GridColumn>
            </GridRow>
          </Grid>
        </div>
      </Dimmer>
    </div>
  );
}
