import React, { useContext } from "react";
import {
  Container,
  Header,
  CardHeader,
  CardDescription,
  CardContent,
  Card,
  Grid,
  GridColumn,
  GridRow,
} from "semantic-ui-react";
import { UserContext } from "../shared/UserContext";
import { Helmet } from "react-helmet-async";
import { getMenuOptions, getMenuPages } from "../../logic/menuOptionGenerator";

const MenuCard = (props) => {
  const { title, description } = props;

  return (
    <Card>
      <CardContent>
        <CardHeader>{title}</CardHeader>
        <CardDescription>{description}</CardDescription>
      </CardContent>
    </Card>
  );
};

export default function MetaData() {
  const user = useContext(UserContext);
  const menuOpts = {
    columns: 3,
    Canvassing: {
      show: false,
      column: 1,
      pages: [
        {
          title: "Top Canvassers",
          link: "/top-canvassers",
          description: "Top canvassers nationwide from the last 7 days",
          permission: "connectCanvassers",
          show: false,
          icon: "table",
        },
        {
          title: "MP Canvassers",
          link: "/mp-canvassers",
          description: "MP canvass stats",
          permission: "mpCanvassers",
          show: false,
          icon: "table",
        },
        {
          title: "Missing Canvassers",
          link: "/missing-canvassers",
          description: "Canvassers without a contact record",
          permission: "connectCanvassers",
          show: false,
          icon: "table",
        },
      ],
    },
    "Register Status": {
      show: false,
      column: 2,
      pages: [
        {
          title: "Register status",
          link: "/register-status-table",
          description: "Current status in processing electoral registers",
          permission: "registerStatus",
          show: false,
          icon: "table",
        },
      ],
    },
    "Postal Vote clearances": {
      show: false,
      column: 2,
      pages: [
        {
          title: "Postal Vote clears",
          link: "/postalVoteClears",
          description: "",
          permission: "viewPVClears",
          show: false,
          icon: "table",
        },
      ],
    },
    "Register Status": {
      show: false,
      column: 2,
      pages: [
        {
          title: "Register status",
          link: "/register-status-table",
          description: "Current status in processing electoral registers",
          permission: "registerStatus",
          show: false,
          icon: "table",
        },
      ],
    },
    "MyCampaign Stats": {
      show: false,
      column: 2,
      pages: [
        {
          title: "MyCampaign stats",
          link: "/my-campaign-stats",
          description: "",
          permission: "connectMetadata",
          show: false,
          icon: "linegraph",
        },
      ],
    },
    Forms: {
      show: false,
      column: 3,
      pages: [
        {
          title: "Top Forms",
          link: "/top-forms-table",
          description: "Highest performing fleet forms",
          permission: "connectMetadata",
          show: false,
          icon: "table",
        },
        {
          title: "Forms processing stats",
          link: "/forms-processing",
          description: "Recent forms status",
          permission: "connectMetadata",
          show: false,
          icon: "linegraph",
        },
      ],
    },
    "MiniVAN Syncs": {
      show: false,
      column: 3,
      pages: [
        {
          title: "MiniVAN Syncs",
          link: "/minivan-syncs",
          description: "",
          permission: "connectMetadata",
          show: false,
          icon: "table",
        },
      ],
    },
    "Email Events": {
      show: false,
      column: 3,
      pages: [
        {
          title: "Email Events Log",
          link: "/emailEvents",
          description: "",
          permission: "dataCheckup",
          show: false,
          icon: "table",
        },
      ],
    },
    "PDHA Checkup": {
      show: false,
      column: 3,
      pages: [
        {
          title: "PDHA Checkup",
          link: "/pdha-checkup",
          description: "",
          permission: "connectMetadata",
          show: false,
          icon: "map pin",
        },
      ],
    },
  };

  function renderMenuCards() {
    let opts = getMenuOptions(menuOpts, user);

    return [...Array(menuOpts.columns).keys()].map((i) => {
      i++;
      return (
        <GridColumn key={i}>
          {Object.keys(opts).map((option) => {
            if (option !== "columns") {
              return opts[option].show && opts[option].column === i ? (
                <MenuCard key={option} title={option} description={getMenuPages(menuOpts[option].pages)} />
              ) : (
                <></>
              );
            }
          })}
        </GridColumn>
      );
    });
  }

  return (
    <Container>
      <Helmet>
        <title>MetaData</title>
      </Helmet>
      <Header as="h2">Connect metadata</Header>
      <Grid columns={menuOpts.columns}>{renderMenuCards()}</Grid>
    </Container>
  );
}
