import React, { useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { flexRender, getCoreRowModel, getSortedRowModel, useReactTable } from "@tanstack/react-table";
import { Container, Grid, GridColumn, Header, Icon } from "semantic-ui-react";
import { UserContext } from "../shared/UserContext";
import { userManage } from "../../logic/apiHandler";
import Dimmer from "../shared/Dimmer";
import { Helmet } from "react-helmet-async";
import ErrorBoundary from "../shared/ErrorBoundary";

export default function UserData() {
  const [userList, setUserList] = useState([]);
  const user = useContext(UserContext);
  const [error, setError] = useState(null);
  const [dataLoaded, setDataLoaded] = useState(false);

  useEffect(() => {
    setDataLoaded(false);
    setError(null);
    setUserList([]);

    (async () => {
      setDataLoaded(false);
      setError(null);
      setUserList([]);

      try {
        let data = await userManage.userList();
        data.map((u) => {
          u.areas = u?.hasNationalPermissions
            ? "National access"
            : u?.districts.length === 0
            ? "No seat access"
            : u.districts;
          return u;
        });
        setUserList(data);
        setDataLoaded(true);
      } catch (e) {
        console.log(e);
        setDataLoaded(true);
        setError(e);
      }
    })();
  }, []);

  const columns = [
    {
      header: "ID",
      accessorKey: "userID",
    },
    {
      header: "Name",
      accessorKey: "surname", // sorts alphabetically on surname
      cell: ({ row }) => {
        let forename = row.original.forename.length ? row.original.forename : `<blank>`;
        let surname = row.original.surname.length ? row.original.surname : `<blank>`;

        return row.original.editable ? (
          <Link to={`/users/edit/${row.original.userID}`}>
            {forename} {surname}
          </Link>
        ) : (
          `${forename} ${surname}`
        );
      },
    },
    {
      header: "Email",
      accessorKey: "email",
    },
    {
      header: "Roles",
      accessorKey: "roles",
    },
    {
      header: "Active",
      accessorKey: "active",
    },
    {
      header: "Areas",
      accessorKey: "areas",
    },
    {
      header: "Last Access",
      accessorKey: "lastAccess",
      cell: ({ row }) => lastSeen(row.original.lastAccess),
      sortingFn: (a, b) => {
        return new Date(a.original.lastAccess).getTime() > new Date(b.original.lastAccess).getTime() ? -1 : 1;
      },
    },
  ];

  const table = useReactTable({
    columns,
    data: userList,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  function lastSeen(lastSeenDate) {
    if (!lastSeenDate) {
      return "not logged in";
    }
    const now = new Date();
    lastSeenDate = new Date(lastSeenDate);

    const differenceMins = Math.round((now - lastSeenDate) / 60000);
    const differenceHours = Math.round(differenceMins / 60);
    const differenceDays = Math.round(differenceHours / 24);

    if (differenceMins <= 1) {
      return "a minute ago";
    } else if (differenceMins <= 59) {
      return `${differenceMins} minutes ago`;
    } else if (differenceHours === 1) {
      return "an hour ago";
    } else if (differenceHours < 24) {
      return `${differenceHours} hours ago`;
    } else if (differenceDays === 1) {
      return `a day ago`;
    } else if (differenceDays < 30) {
      return `${differenceDays} days ago`;
    } else if (differenceDays <= 31) {
      return "last month";
    } else {
      return lastSeenDate.toLocaleDateString("en-GB");
    }
  }

  return (
    <div>
      <ErrorBoundary fallback={<p>Oops!</p>}>
        <Container fluid>
          <Helmet>
            <title>Dashboard user data</title>
          </Helmet>
          <Grid columns={2}>
            <GridColumn>
              <Header as="h2">Dashboard user data</Header>
            </GridColumn>
            <GridColumn floated="right" width={1}>
              <Link to="/users/add">
                <Icon name="add user" size="large" style={{ color: "#FAA61A" }} link={true} />
              </Link>
            </GridColumn>
          </Grid>
          <Dimmer error={error} dataLoaded={dataLoaded}>
            <table>
              <thead>
                {table.getHeaderGroups().map((headerGroup) => (
                  <tr key={headerGroup.id}>
                    {headerGroup.headers.map((header) => (
                      <th key={header.id} colSpan={header.colSpan}>
                        <div
                          className={header.column.getCanSort() ? "cursor-pointer select-none" : ""}
                          onClick={header.column.getToggleSortingHandler()}
                          title={
                            header.column.getCanSort()
                              ? header.column.getNextSortingOrder() === "asc"
                                ? "Sort ascending"
                                : header.column.getNextSortingOrder() === "desc"
                                ? "Sort descending"
                                : "Clear sort"
                              : undefined
                          }
                        >
                          {flexRender(header.column.columnDef.header, header.getContext())}
                          {{
                            asc: <Icon name="caret up" color="yellow" />,
                            desc: <Icon name="caret down" color="yellow" />,
                          }[header.column.getIsSorted()] ?? null}
                        </div>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody>
                {table.getRowModel().rows.map((row) => {
                  return (
                    <tr key={row.id}>
                      {row.getVisibleCells().map((cell) => {
                        return <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>;
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </Dimmer>
        </Container>
      </ErrorBoundary>
    </div>
  );
}
