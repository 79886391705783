import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Container, Grid, GridColumn } from "semantic-ui-react";
import CanvassChartsGraph from "./CanvassChartsGraph";
import { getGraphingData } from "../../logic/sharedProcessor";
import api from "../../logic/apiHandler";
import { Helmet } from "react-helmet-async";
import Dimmer from "../shared/Dimmer";

export default function CanvassCharts({ areaName }) {
  const [canvassData, setCanvassData] = useState([]);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [error, setError] = useState(null);

  let { areaID } = useParams();

  useEffect(() => {
    async function getCanvassData() {
      setDataLoaded(false);
      setError(null);
      try {
        let data = await api.seatData.getMPIDCanvassData(areaID);
        setCanvassData(data);
        setDataLoaded(true);
      } catch (e) {
        setError(e);
        setDataLoaded(true);
      }
    }

    getCanvassData();
  }, [areaID]);

  const endDate = new Date().toISOString().substring(0, 10);

  return (
    <div>
      <Helmet>
        <title>{areaName ?? ""} - Canvassing</title>
      </Helmet>
      <Container fluid style={{ position: "relative", minHeight: "200px" }}>
        <Dimmer dataLoaded={dataLoaded} error={error}>
          <Grid doubling columns={3} verticalAlign="bottom">
            <GridColumn style={{ minWidth: "400px" }}>
              <CanvassChartsGraph title="All (7 day)" data={getGraphingData(canvassData, 7, 30, endDate)} />
            </GridColumn>
            <GridColumn style={{ minWidth: "400px" }}>
              <CanvassChartsGraph title="All (3 day)" data={getGraphingData(canvassData, 3, 30, endDate)} />
            </GridColumn>
            <GridColumn style={{ minWidth: "400px" }}>
              <CanvassChartsGraph title="All (1 day)" data={getGraphingData(canvassData, 1, 30, endDate)} />
            </GridColumn>
            <GridColumn style={{ minWidth: "400px" }}>
              <CanvassChartsGraph
                title="Female (7 day)"
                data={getGraphingData(
                  canvassData.filter((p) => p.Sex === "F"),
                  7,
                  30,
                  endDate
                )}
              />
            </GridColumn>
            <GridColumn style={{ minWidth: "400px" }}>
              <CanvassChartsGraph
                title="Male (7 day)"
                data={getGraphingData(
                  canvassData.filter((p) => p.Sex === "M"),
                  7,
                  30,
                  endDate
                )}
              />
            </GridColumn>
            <GridColumn style={{ minWidth: "400px" }}>
              <CanvassChartsGraph
                title="Unknown (7 day)"
                data={getGraphingData(
                  canvassData.filter((p) => p.Sex === ""),
                  7,
                  30,
                  endDate
                )}
              />
            </GridColumn>
            <GridColumn style={{ minWidth: "400px" }}>
              <CanvassChartsGraph
                title="Age: 35- (7 day)"
                data={getGraphingData(
                  canvassData.filter((p) => p.age >= 35),
                  7,
                  30,
                  endDate
                )}
              />
            </GridColumn>
            <GridColumn style={{ minWidth: "400px" }}>
              <CanvassChartsGraph
                title="Age: 35-59 (7 day)"
                data={getGraphingData(
                  canvassData.filter((p) => p.age >= 35 && p.age < 60),
                  7,
                  30,
                  endDate
                )}
              />
            </GridColumn>
            <GridColumn style={{ minWidth: "400px" }}>
              <CanvassChartsGraph
                title="Age: 60+ (7 day)"
                data={getGraphingData(
                  canvassData.filter((p) => p.age < 60),
                  7,
                  30,
                  endDate
                )}
              />
            </GridColumn>
            <GridColumn style={{ minWidth: "400px" }}>
              <CanvassChartsGraph
                title="Year of Update < 2015 (7 day)"
                data={getGraphingData(
                  canvassData.filter((p) => p.yearOfUpdate !== null && p.yearOfUpdate < 2015),
                  7,
                  30,
                  endDate
                )}
              />
            </GridColumn>
            <GridColumn style={{ minWidth: "400px" }}>
              <CanvassChartsGraph
                title="Year of Update 2015-2019 (7 day)"
                data={getGraphingData(
                  canvassData.filter((p) => p.yearOfUpdate && p.yearOfUpdate >= 2015 && p.yearOfUpdate < 2020),
                  7,
                  30,
                  endDate
                )}
              />
            </GridColumn>
            <GridColumn style={{ minWidth: "400px" }}>
              <CanvassChartsGraph
                title="Year of Update > 2020+ (7 day)"
                data={getGraphingData(
                  canvassData.filter((p) => p.yearOfUpdate && p.yearOfUpdate >= 2020),
                  7,
                  30,
                  endDate
                )}
              />
            </GridColumn>
            <GridColumn style={{ minWidth: "400px" }}>
              <CanvassChartsGraph
                title="ConLab > 10 (7 day)"
                data={getGraphingData(
                  canvassData.filter((p) => p.ConLab > 10),
                  7,
                  30,
                  endDate
                )}
              />
            </GridColumn>
            <GridColumn style={{ minWidth: "400px" }}>
              <CanvassChartsGraph
                title="-10 <= ConLab <= 10 (7 day)"
                data={getGraphingData(
                  canvassData.filter((p) => p.ConLab && p.ConLab >= -10 && p.ConLab <= 10),
                  7,
                  30,
                  endDate
                )}
              />
            </GridColumn>
            <GridColumn style={{ minWidth: "400px" }}>
              <CanvassChartsGraph
                title="ConLab < -10 (7 day)"
                data={getGraphingData(
                  canvassData.filter((p) => p.ConLab && p.ConLab < -10),
                  7,
                  30,
                  endDate
                )}
              />
            </GridColumn>
            <GridColumn style={{ minWidth: "400px" }}>
              <CanvassChartsGraph
                title="EU Score > 60 (7 day)"
                data={getGraphingData(
                  canvassData.filter((p) => p.euScore > 60),
                  7,
                  30,
                  endDate
                )}
              />
            </GridColumn>
            <GridColumn style={{ minWidth: "400px" }}>
              <CanvassChartsGraph
                title="EU Score 40-60 (7 day)"
                data={getGraphingData(
                  canvassData.filter((p) => p.euScore >= 40 && p.euScore <= 60),
                  7,
                  30,
                  endDate
                )}
              />
            </GridColumn>
            <GridColumn style={{ minWidth: "400px" }}>
              <CanvassChartsGraph
                title="EU Score < 40 (7 day)"
                data={getGraphingData(
                  canvassData.filter((p) => p.euScore && p.euScore < 40),
                  7,
                  30,
                  endDate
                )}
              />
            </GridColumn>
            <GridColumn style={{ minWidth: "400px" }}>
              <CanvassChartsGraph
                title="Postal (7 day)"
                data={getGraphingData(
                  canvassData.filter((p) => p.postal == "Y"),
                  7,
                  30,
                  endDate
                )}
              />
            </GridColumn>
            <GridColumn style={{ minWidth: "400px" }}>
              <CanvassChartsGraph
                title="Not Postal (7 day)"
                data={getGraphingData(
                  canvassData.filter((p) => p.postal == "N"),
                  7,
                  30,
                  endDate
                )}
              />
            </GridColumn>
            <GridColumn style={{ minWidth: "400px" }}>
              <CanvassChartsGraph
                title="CA Group = Lib (7 day)"
                data={getGraphingData(
                  canvassData.filter((p) => p.caGroup == "Lib"),
                  7,
                  30,
                  endDate
                )}
              />
            </GridColumn>
            <GridColumn style={{ minWidth: "400px" }}>
              <CanvassChartsGraph
                title="CA Group = Lab (7 day)"
                data={getGraphingData(
                  canvassData.filter((p) => p.caGroup == "Lab"),
                  7,
                  30,
                  endDate
                )}
              />
            </GridColumn>
            <GridColumn style={{ minWidth: "400px" }}>
              <CanvassChartsGraph
                title="CA Group = Con (7 day)"
                data={getGraphingData(
                  canvassData.filter((p) => p.caGroup == "Con"),
                  7,
                  30,
                  endDate
                )}
              />
            </GridColumn>
            <GridColumn style={{ minWidth: "400px" }}>
              <CanvassChartsGraph
                title="CA Group = Nat (7 day)"
                data={getGraphingData(
                  canvassData.filter((p) => p.caGroup == "Nat"),
                  7,
                  30,
                  endDate
                )}
              />
            </GridColumn>
            <GridColumn style={{ minWidth: "400px" }}>
              <CanvassChartsGraph
                title="CA Group = Gre (7 day)"
                data={getGraphingData(
                  canvassData.filter((p) => p.caGroup == "Gre"),
                  7,
                  30,
                  endDate
                )}
              />
            </GridColumn>
            <GridColumn style={{ minWidth: "400px" }}>
              <CanvassChartsGraph
                title="CA Group = Unk (7 day)"
                data={getGraphingData(
                  canvassData.filter((p) => p.caGroup == "Unk"),
                  7,
                  30,
                  endDate
                )}
              />
            </GridColumn>
          </Grid>
        </Dimmer>
      </Container>
    </div>
  );
}
