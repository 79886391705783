import React, { useEffect, useState } from "react";
import api from "../../logic/apiHandler";
import { Helmet } from "react-helmet-async";
import {
  Header,
  Grid,
  GridColumn,
  GridRow,
  Statistic,
  StatisticGroup,
  StatisticLabel,
  StatisticValue,
} from "semantic-ui-react";
import FormStatsBarChart from "./FormStatsBarChart";

export default function FormsProcessingScreen() {
  const [formStats, setFormStats] = useState({});
  const [dataLoaded, setDataLoaded] = useState(false);

  useEffect(async () => {
    setDataLoaded(false);
    try {
      setFormStats(await api.metaData.allForms());
      setDataLoaded(true);
    } catch (e) {
      console.log("error", e);
    }
  }, []);

  return (
    <div>
      <Helmet>
        <title>Form processing stats</title>
      </Helmet>
      <Header as="h2">Form processing stats</Header>
      {dataLoaded && (
        <Grid columns={2}>
          <GridRow>
            <GridColumn>
              <StatisticGroup>
                <Header>VAN:</Header>
                <Statistic size="tiny">
                  <StatisticValue>{formStats.vanData.unprocessed ?? "-"}</StatisticValue>
                  <StatisticLabel>unprocessed</StatisticLabel>
                </Statistic>
                <Statistic size="tiny">
                  <StatisticValue>{formStats.vanData.last2Months ?? "-"}</StatisticValue>
                  <StatisticLabel>last 2 months</StatisticLabel>
                </Statistic>
                <Statistic size="tiny">
                  <StatisticValue>{formStats.vanData.lastDay ?? "-"}</StatisticValue>
                  <StatisticLabel>last day</StatisticLabel>
                </Statistic>
                <Statistic size="tiny">
                  <StatisticValue>{formStats.vanData.lastHour ?? "-"}</StatisticValue>
                  <StatisticLabel>last hour</StatisticLabel>
                </Statistic>
              </StatisticGroup>
            </GridColumn>
            <GridColumn>
              <StatisticGroup>
                <Header>SF:</Header>
                <Statistic size="tiny">
                  <StatisticValue>{formStats.salesforceData.unprocessed ?? "-"}</StatisticValue>
                  <StatisticLabel>unprocessed</StatisticLabel>
                </Statistic>
                <Statistic size="tiny">
                  <StatisticValue>{formStats.salesforceData.last2Months ?? "-"}</StatisticValue>
                  <StatisticLabel>last 2 months</StatisticLabel>
                </Statistic>
                <Statistic size="tiny">
                  <StatisticValue>{formStats.salesforceData.lastDay ?? "-"}</StatisticValue>
                  <StatisticLabel>last day</StatisticLabel>
                </Statistic>
                <Statistic size="tiny">
                  <StatisticValue>{formStats.salesforceData.lastHour ?? "-"}</StatisticValue>
                  <StatisticLabel>last hour</StatisticLabel>
                </Statistic>
              </StatisticGroup>
            </GridColumn>
          </GridRow>
          <GridRow>
            <GridColumn>
              <h3>Last 2 Months</h3>
              <FormStatsBarChart data={formStats.last2Months} type="last2Months" />
            </GridColumn>
            <GridColumn>
              <h3>Last 2 Days</h3>
              <FormStatsBarChart data={formStats.last2Days} type="last2Days" />
            </GridColumn>
          </GridRow>
        </Grid>
      )}
    </div>
  );
}
