import React, { useEffect, useState, useContext } from "react";
import { UserContext } from "../shared/UserContext";
import { Link } from "react-router-dom";
import { Button, ButtonGroup, List, ListItem, Checkbox, ListIcon, ListContent, Popup } from "semantic-ui-react";
import SeatLevelLabel from "../shared/SeatLevelLabel";

export default function ConstituencySelectionScreen() {
  const [constituencies, setConstituencies] = useState({});
  const [activeAreaType, setActiveAreaType] = useState("westminster2024");
  const [targetOnly, setTargetOnly] = useState(true);
  const user = useContext(UserContext);

  useEffect(() => {
    setConstituencies(user?.allowedConstituencies ?? {});
  }, [user]);

  function renderConstituencies() {
    if (constituencies[activeAreaType]) {
      let listOptions = constituencies[activeAreaType];
      if (targetOnly) {
        let targetList = getTargetsOnly(listOptions);
        if (targetList.length !== 0) {
          listOptions = targetList;
        }
      }

      listOptions = formatListOptions(listOptions);
      return (
        <div style={{ columnCount: 3 }}>
          <List>{listOptions}</List>
        </div>
      );
    }
  }

  function formatListOptions(constituencyList) {
    return constituencyList.map((constituency) => {
      const tag = constituency.label ? <SeatLevelLabel label={constituency.label.toLowerCase()} /> : null;
      return (
        <ListItem key={constituency.code}>
          <ListIcon className="lineGraph" />
          <ListContent>
            <Link to={constituency.code}>{constituency.name}</Link>&nbsp;
            {tag}
          </ListContent>
        </ListItem>
      );
    });
  }

  function getAreaTypeButtons() {
    const links = Object.keys(constituencies).map((areaType) => {
      let areaName = areaType.charAt(0).toUpperCase() + areaType.slice(1);
      areaName = areaName.match(/[A-Z][a-z]+|[0-9]+/g).join(" ");
      if (areaName !== "Westminster") {
        return (
          <Button
            key={areaType}
            onClick={() => setActiveAreaType(areaType)}
            color={activeAreaType === areaType ? "yellow" : null}
          >
            {areaName}
          </Button>
        );
      }
    });
    return links;
  }

  function getTargetsOnly(seats) {
    if (Object.keys(seats).length) {
      return seats.filter((constituency) => constituency.label !== null);
    }
  }

  return (
    <div>
      View by: <ButtonGroup style={{ marginTop: "20px", marginBottom: "20px" }}>{getAreaTypeButtons()}</ButtonGroup>{" "}
      &nbsp;&nbsp;&nbsp;&nbsp; Show targets only&nbsp;&nbsp;
      <Popup
        trigger={
          <Checkbox
            className="mini"
            toggle
            disabled={constituencies[activeAreaType] ? !getTargetsOnly(constituencies[activeAreaType]).length : false}
            checked={targetOnly}
            onChange={(e, data) => setTargetOnly(data.checked)}
            style={{ marginRight: "10px", top: "5px" }}
          />
        }
        open={constituencies[activeAreaType] ? !getTargetsOnly(constituencies[activeAreaType]).length : false}
        content="No target seats for this area type; displaying all seats"
        position="right center"
      />
      {renderConstituencies()}
    </div>
  );
}
