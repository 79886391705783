import React, { useContext } from "react";
import { Container, Header, CardHeader, CardDescription, CardContent, Card } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { UserContext } from "../shared/UserContext";
import { canAccess } from "../../logic/userPermissionsHandler";
import { Helmet } from "react-helmet-async";

const MenuCard = (props) => {
  const { link, title, description } = props;

  return (
    <Card>
      <CardContent>
        <CardHeader>
          <Link to={link}>{title}</Link>
        </CardHeader>
        <CardDescription>{description}</CardDescription>
      </CardContent>
    </Card>
  );
};

export default function NationalData() {
  const user = useContext(UserContext);
  return (
    <div>
      <Helmet>
        <title>National Data</title>
      </Helmet>
      <Container>
        <Header as="h2">National data</Header>
        {canAccess(user, "nationalCampaignStat") ? (
          <MenuCard link="/nationalData/geSummary" title="GE summary" description="" />
        ) : null}
        {canAccess(user, "nationalCampaignStat") ? (
          <MenuCard link="/nationalData/constituencyActivity" title="Constituency Activity" />
        ) : null}
      </Container>
    </div>
  );
}
