import React, { useMemo, useState, useEffect } from "react";
import { flexRender, getCoreRowModel, getSortedRowModel, useReactTable } from "@tanstack/react-table";
import { metaData } from "../../logic/apiHandler";
import { Icon, Button } from "semantic-ui-react";
import { Helmet } from "react-helmet-async";
import Dimmer from "../shared/Dimmer";
import { useNavigate, Link } from "react-router-dom";
import { convertIDToHex } from "../../logic/hexStringConverter";

export default function MissingCanvassersTable() {
  const [missingCanvassers, setMissingCanvassers] = useState([]);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    async function getCanvasserData() {
      setDataLoaded(false);
      setError(null);

      try {
        setMissingCanvassers(await metaData.missingCanvassers());
        setDataLoaded(true);
      } catch (e) {
        setError(e);
        setDataLoaded(true);
      }
    }

    getCanvasserData();
  }, []);

  const columns = useMemo(
    () => [
      {
        header: "ID",
        accessorKey: "userID",
        cell: ({ row }) => (
          <Link
            to={`https://connect.libdems.org.uk/UserDetails.aspx?UserID=${convertIDToHex(row.original.userID)}`}
            target="_blank"
          >
            {row.original.userID}
          </Link>
        ),
      },
      {
        header: "Attempts last 7 days",
        accessorKey: "doorPhoneContactsLast7Days",
      },
      {
        header: "Attempts last 3 months",
        accessorKey: "doorPhoneContactsLast3Mths",
      },
      {
        header: "Attempts last 18 months",
        accessorKey: "doorPhoneContactsLast18Mths",
      },
      {
        header: "Canvassed Count",
        accessorKey: "resultCanvassedCount",
      },
      {
        header: "username",
        accessorKey: "username",
      },
      {
        header: "First Name",
        accessorKey: "FirstName",
      },
      {
        header: "Last Name",
        accessorKey: "LastName",
      },
      {
        header: "Post Code",
        accessorKey: "PostalCode",
      },
      {
        header: "Email",
        accessorKey: "Email",
      },
      {
        header: "Phone number",
        accessorKey: "Phone",
      },
      {
        header: "Is Active?",
        accessorKey: "IsActive",
        cell: ({ row }) => Boolean(row.original.IsActive).toString(),
      },
    ],
    []
  );

  const table = useReactTable({
    columns,
    data: missingCanvassers,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  return (
    <div>
      <Helmet>
        <title>Missing Canvassers</title>
      </Helmet>
      <h3>
        Missing Canvassers &nbsp;
        <Button basic onClick={() => navigate("/top-canvassers")}>
          See top canvassers
        </Button>
      </h3>
      <p>Top canvassers for the last 7 days, where the user account is not linked to a MyCampaign record</p>
      <Dimmer error={error} dataLoaded={dataLoaded}>
        <div className="sticky-table-container">
          <table>
            <thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <th
                      key={header.id}
                      colSpan={header.colSpan}
                      className={header.column.getCanSort() ? "cursor-pointer select-none" : ""}
                      onClick={header.column.getToggleSortingHandler()}
                      title={
                        header.column.getCanSort()
                          ? header.column.getNextSortingOrder() === "asc"
                            ? "Sort ascending"
                            : header.column.getNextSortingOrder() === "desc"
                            ? "Sort descending"
                            : "Clear sort"
                          : undefined
                      }
                    >
                      {flexRender(header.column.columnDef.header, header.getContext())}
                      {{
                        asc: <Icon name="caret up" color="yellow" />,
                        desc: <Icon name="caret down" color="yellow" />,
                      }[header.column.getIsSorted()] ?? null}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody>
              {table.getRowModel().rows.map((row) => {
                return (
                  <tr key={row.id}>
                    {row.getVisibleCells().map((cell) => {
                      return <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>;
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </Dimmer>
    </div>
  );
}
