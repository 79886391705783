import React, { useState, useEffect } from "react";
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
  createColumnHelper,
  getSortedRowModel,
} from "@tanstack/react-table";
import { Helmet } from "react-helmet-async";
import api from "../../logic/apiHandler";
import Dimmer from "../shared/Dimmer";
import { Header } from "semantic-ui-react";
import { Link, generatePath } from "react-router-dom";
import { getChesterGraphing } from "../../logic/sharedProcessor";
import SeatLevelLabel from "../shared/SeatLevelLabel";
import { Icon } from "semantic-ui-react";

export default function GESummaryTable({ areaName }) {
  const targetsOnly = true;
  const [geData, setGEData] = useState([]);
  const [displayData, setDisplayData] = useState([]);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    async function getGEData() {
      setDataLoaded(false);
      setError(null);

      try {
        const data = await api.nationalData.geTomorrow();
        setGEData(data);
        setDataLoaded(true);
        setError(null);
      } catch (e) {
        setError(e);
        setDataLoaded(true);
      }
    }

    getGEData();
  }, []);

  const columnHelper = createColumnHelper();

  const columns = [
    columnHelper.group({
      id: "Constituency",
      header: () => <span></span>,
      columns: [
        columnHelper.accessor("Constituency", {
          id: "Constituency",
          cell: ({ row }) => (
            <Link style={{ float: "left" }} to={generatePath(`/seatData/${row.original.constituency.code}/geCanvass`)}>
              {row.original.constituency.name} <SeatLevelLabel label={row.original.constituency.label} />
            </Link>
          ),
        }),
      ],
    }),
    columnHelper.group({
      id: "7day",
      header: () => "7 day",
      columns: [
        columnHelper.accessor("7dcount", {
          header: () => "Total",
        }),
        columnHelper.accessor("7dcLD", {
          header: () => "LD",
        }),
        columnHelper.accessor("7dcCon", {
          header: () => "Con",
        }),
        columnHelper.accessor("7dcLab", {
          header: () => "Lab",
        }),
        columnHelper.accessor("7dcSNP", {
          header: () => "SNP",
        }),
        columnHelper.accessor("7dcPla", {
          header: () => "Pla",
        }),
        columnHelper.accessor("7dcGre", {
          header: () => "Gre",
        }),
        columnHelper.accessor("7dcUKI", {
          header: () => "Ref",
        }),
      ],
    }),
    columnHelper.group({
      id: "1mth",
      header: () => "1 month",
      columns: [
        columnHelper.accessor("1mcount", {
          header: () => "Total",
        }),
        columnHelper.accessor("1mcLD", {
          header: () => "LD",
        }),
        columnHelper.accessor("1mcCon", {
          header: () => "Con",
        }),
        columnHelper.accessor("1mcLab", {
          header: () => "Lab",
        }),
        columnHelper.accessor("1mcSNP", {
          header: () => "SNP",
        }),
        columnHelper.accessor("1mcPla", {
          header: () => "Pla",
        }),
        columnHelper.accessor("1mcGre", {
          header: () => "Gre",
        }),
        columnHelper.accessor("1mcUKI", {
          header: () => "Ref",
        }),
      ],
    }),
    columnHelper.group({
      id: "18 months",
      header: () => "18 months",
      columns: [
        columnHelper.accessor("18mcount", {
          header: () => "Total",
        }),
        columnHelper.accessor("18mcLD", {
          header: () => "LD",
        }),
        columnHelper.accessor("18mcCon", {
          header: () => "Con",
        }),
        columnHelper.accessor("18mcLab", {
          header: () => "Lab",
        }),
        columnHelper.accessor("18mcSNP", {
          header: () => "SNP",
        }),
        columnHelper.accessor("18mcPla", {
          header: () => "Pla",
        }),
        columnHelper.accessor("18mcGre", {
          header: () => "Gre",
        }),
        columnHelper.accessor("18mcUKI", {
          header: () => "Ref",
        }),
      ],
    }),
  ];

  useEffect(() => {
    setDisplayData(formatTableData(geData));
  }, [geData, targetsOnly]);

  function formatTableData(data) {
    let tableData = [];
    for (let row of data) {
      if (targetsOnly && (!row.label || row.label === "")) {
        continue;
      }

      row.wk = strToInt(row.wk);
      row.mth = strToInt(row.mth);
      row["18mth"] = strToInt(row["18mth"]);

      row["18mth"].total = row["18mth"].All - row["18mth"].Unk;
      row.mth.total = row.mth.All - row.mth.Unk;
      row.wk.total = row.wk.All - row.wk.Unk;

      let rowOutput = {
        constituency: { name: row.name, code: row.code, label: row.label },
      };

      let Twk = getChesterGraphing(row.wk);
      let Tmth = getChesterGraphing(row.mth);
      let T18mth = getChesterGraphing(row["18mth"]);

      for (let k of Object.keys(Twk)) {
        rowOutput[`7d${k}`] = isNaN(Twk[k]) ? "-" : Twk[k];
      }
      for (let k of Object.keys(Tmth)) {
        rowOutput[`1m${k}`] = isNaN(Tmth[k]) ? "-" : Tmth[k];
      }
      for (let k of Object.keys(T18mth)) {
        rowOutput[`18m${k}`] = isNaN(T18mth[k]) ? "-" : T18mth[k];
      }

      tableData.push(rowOutput);
    }
    return tableData;
  }

  function strToInt(data) {
    for (let party of Object.keys(data)) {
      data[party] = parseInt(data[party]);
    }
    return data;
  }

  const table = useReactTable({
    columns,
    data: displayData,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  return (
    <div>
      <Helmet>
        <title>{areaName ?? ""} - GE Summary Table</title>
      </Helmet>
      <Header as="h2">National GE summary</Header>
      <Dimmer error={error} dataLoaded={dataLoaded}>
        <div className="sticky-table-container">
          <table>
            <thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <th
                      key={header.id}
                      colSpan={header.colSpan}
                      onClick={header.column.getToggleSortingHandler()}
                      title={
                        header.column.getCanSort()
                          ? header.column.getNextSortingOrder() === "asc"
                            ? "Sort ascending"
                            : header.column.getNextSortingOrder() === "desc"
                            ? "Sort descending"
                            : "Clear sort"
                          : undefined
                      }
                    >
                      {flexRender(header.column.columnDef.header, header.getContext())}
                      {{
                        asc: <Icon name="caret up" color="yellow" />,
                        desc: <Icon name="caret down" color="yellow" />,
                      }[header.column.getIsSorted()] ?? null}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody>
              {table.getRowModel().rows.map((row) => {
                return (
                  <tr key={row.id}>
                    {row.getVisibleCells().map((cell) => {
                      return <td key={cell.column.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>;
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </Dimmer>
    </div>
  );
}
