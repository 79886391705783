const { canAccess } = require("./userPermissionsHandler");
const React = require("react");
const { Link } = require("react-router-dom");
const { Icon } = require("semantic-ui-react");

export function getMenuOptions(opts, user) {
  for (let option of Object.keys(opts).filter((option) => option !== "columns")) {
    for (let page of opts[option].pages) {
      if (canAccess(user, page.permission)) {
        opts[option].show = true;
        page.show = true;
      }
    }
  }
  return opts;
}

export function getMenuPages(pages) {
  let res = [];
  for (let page of pages) {
    if (page.show) {
      res.push(
        <li key={page.title} style={{ listStyleType: "none" }}>
          <Icon className={page.icon} />
          <Link to={page.link} style={{ fontSize: "16px", paddingBottom: "10px" }}>
            {page.title}
          </Link>
          <ul style={{ listStyleType: "none" }}>
            <li key={`${page.title}_description`}>{page.description}</li>
          </ul>
        </li>
      );
    }
  }
  return res;
}
