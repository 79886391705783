import React, { useState } from "react";
import { Form, FormButton, FormDropdown, FormInput, Grid, GridColumn } from "semantic-ui-react";

export default function LAForm({ addMarkers, setMarkers, addError, removeError, errorMessages }) {
  const [LA, setLA] = useState("");
  const [hasLAError, setHasLAError] = useState(false);
  const [PD, setPD] = useState("");
  const [hasPDError, setHasPDError] = useState(false);
  const [table, setTable] = useState("changes");

  function handleSubmit() {
    const validLA = isValidLA();
    const validPD = isValidPD();
    if (validLA && validPD) {
      addMarkers(LA, PD, table);
    }
  }

  function clearForm() {
    setLA("");
    setHasLAError(false);
    setPD("");
    setHasPDError(false);
    setTable("changes");
    setMarkers([]);
  }

  function isValidLA() {
    if (LA === "" || /^[ENSW][0-9]{8}$/.test(LA)) {
      setHasLAError(false);
      removeError(errorMessages.LA);
      return true;
    }

    addError(errorMessages.LA);
    return false;
  }

  function isValidPD() {
    if (PD === "" || /^[A-Za-z0-9 .&/\\()_*\\-]{1,10}$/.test(PD)) {
      setHasPDError(false);
      removeError(errorMessages.PD);
      return true;
    }

    addError(errorMessages.PD);
    return false;
  }

  // E05011396
  return (
    <Form>
      <Grid verticalAlign="bottom" style={{ midWidth: "100%" }}>
        <GridColumn width={3}>
          <FormInput
            label="LA"
            placeholder="LA"
            value={LA}
            onChange={(e) => setLA(e.target.value.toUpperCase())}
            error={hasLAError}
          />
        </GridColumn>
        <GridColumn width={3}>
          <FormInput
            label="PD"
            placeholder="PD"
            value={PD}
            onChange={(e) => setPD(e.target.value)}
            error={hasPDError}
          />
        </GridColumn>
        <GridColumn width={3}>
          <FormDropdown
            label="Map Type"
            onChange={(e, d) => setTable(d.value)}
            defaultValue="changes"
            selection
            options={[
              { text: "Changes", value: "changes", key: "changes" },
              { text: "Live", value: "live", key: "live" },
            ]}
          />
        </GridColumn>
        <GridColumn style={{ minWidth: "170px" }}>
          <FormButton
            onClick={handleSubmit}
            variant="primary"
            style={{ backgroundColor: "#FAA61A" }}
            content="Add Markers"
            disabled={!LA || !PD || !table}
          />
        </GridColumn>
        <GridColumn>
          <FormButton onClick={clearForm}>Clear</FormButton>
        </GridColumn>
      </Grid>
    </Form>
  );
}
