import React, { useEffect, useState } from "react";
import { flexRender, getCoreRowModel, getSortedRowModel, useReactTable } from "@tanstack/react-table";
import { Helmet } from "react-helmet-async";
import { useParams } from "react-router-dom";
import api from "../../logic/apiHandler";
import { Button, Icon, Modal, ModalActions, ModalHeader } from "semantic-ui-react";
import Dimmer from "../shared/Dimmer";

export default function EmailSendLog() {
  const [data, setData] = useState([]);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [error, setError] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [modalContent, setModalContent] = useState("");
  const { committeeID } = useParams();

  useEffect(() => {
    (async () => {
      setDataLoaded(false);
      setError(null);

      try {
        setData(await api.committeeData.emailSends(committeeID));
        setDataLoaded(true);
      } catch (e) {
        setError(e);
        setDataLoaded(true);
      }
    })();
  }, [committeeID]);

  const columns = [
    {
      header: "ID",
      accessorKey: "BatchEmailJobID",
      cell: ({ row }) => (
        <Modal
          onClose={() => handleCloseModal()}
          onOpen={() => handleOpenModal(row.original.BatchEmailJobID)}
          open={openModal}
          trigger={<a>{row.original.BatchEmailJobID}</a>}
        >
          <ModalHeader dangerouslySetInnerHTML={{ __html: modalContent }} />
          <ModalActions>
            <Button onClick={() => handleCloseModal()} style={{ backgroundColor: "#faa61a" }}>
              Close
            </Button>
          </ModalActions>
        </Modal>
      ),
    },
    {
      header: "Recipients",
      accessorKey: "recipientCount",
    },
    {
      header: "Name",
      accessorKey: "EmailName",
    },
    {
      header: "Subject",
      accessorKey: "SubjectLine",
    },
    {
      header: "Unique Open Rate",
      accessorKey: "UniqueOpenRate",
      cell: ({ row }) => formatPercentage(row.original.UniqueOpenRate),
    },
    {
      header: "Unique Click Rate",
      accessorKey: "UniqueClickRate",
      cell: ({ row }) => formatPercentage(row.original.UniqueClickRate),
    },
    {
      header: "Bounce Rate",
      accessorKey: "BounceRate",
      cell: ({ row }) => formatPercentage(row.original.BounceRate),
    },
    {
      header: "Unsubscribe Rate",
      accessorKey: "UnsubscribeRate",
      cell: ({ row }) => formatPercentage(row.original.UnsubscribeRate),
    },
    {
      header: "Recipients",
      accessorKey: "RecipientList",
    },
    {
      header: "Excluded",
      accessorKey: "ExcludedList",
    },
    {
      header: "First Sent",
      accessorKey: "FirstSentDate",
      cell: ({ row }) => (row.original.FirstSentDate ? row.original.FirstSentDate.split("T")[0] : null),
    },
    {
      header: "From",
      accessorKey: "FromEmail",
    },
    {
      header: "From",
      accessorKey: "FromName",
    },
  ];

  async function handleOpenModal(emailID) {
    try {
      const res = await api.committeeData.emailContent(committeeID, emailID);
      setModalContent(`<div><h2>Email content may have been truncated</h2>${res.HTML}</div>`);
    } catch (e) {
      console.log("e", e);
      setModalContent("Cannot get the contents of this email at this time. Please try again later.");
    }
    setOpenModal(true);
  }

  function handleCloseModal() {
    setModalContent("");
    setOpenModal(false);
  }

  function formatPercentage(num) {
    num = Math.floor(num * 100);
    return num ? num : "-";
  }

  const table = useReactTable({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  return (
    <div>
      <Helmet>
        <title>Email Send Log</title>
      </Helmet>

      <Dimmer dataLoaded={dataLoaded} error={error}>
        <table>
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th key={header.id} colSpan={header.colSpan}>
                    <div
                      className={header.column.getCanSort() ? "cursor-pointer select-none" : ""}
                      onClick={header.column.getToggleSortingHandler()}
                      title={
                        header.column.getCanSort()
                          ? header.column.getNextSortingOrder() === "asc"
                            ? "Sort ascending"
                            : header.column.getNextSortingOrder() === "desc"
                            ? "Sort descending"
                            : "Clear sort"
                          : undefined
                      }
                    >
                      {flexRender(header.column.columnDef.header, header.getContext())}
                      {{
                        asc: <Icon name="caret up" color="yellow" />,
                        desc: <Icon name="caret down" color="yellow" />,
                      }[header.column.getIsSorted()] ?? null}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => {
              return (
                <tr key={row.id}>
                  {row.getVisibleCells().map((cell) => {
                    return <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>;
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </Dimmer>
    </div>
  );
}
