import React, { useEffect, useState } from "react";
import { flexRender, getCoreRowModel, getSortedRowModel, useReactTable } from "@tanstack/react-table";
import api from "../../logic/apiHandler";
import { Helmet } from "react-helmet-async";
import Dimmer from "../shared/Dimmer";
import { Icon } from "semantic-ui-react";
import { useParams } from "react-router-dom";

export default function PVUploadRecency() {
  const { areaID } = useParams();
  const [uploadData, setUploadData] = useState([]);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    (async () => {
      setDataLoaded(false);
      setError(null);
      try {
        setUploadData(await api.seatData.pvUploadSummary(areaID));
        setDataLoaded(true);
      } catch (e) {
        console.log("e", e);
        setError(e);
        setDataLoaded(true);
      }
    })();
  }, [areaID]);

  const columns = [
    {
      header: "Local Authority",
      accessorKey: "District_Borough_Unitary_Region_Name",
    },
    {
      header: "Polling District",
      accessorKey: "POLLING_DISTRICT",
    },
    {
      header: "MinModDate",
      accessorKey: "MinModDate",
      cell: ({ row }) => getDateIndicator(row.original.MinModDate),
    },
    {
      header: "MaxModDate",
      accessorKey: "MaxModDate",
      cell: ({ row }) => getDateIndicator(row.original.MaxModDate),
    },
    {
      header: "Voter Count",
      accessorKey: "VoterCount",
    },
    {
      header: "PostalVoteCount",
      accessorKey: "PostalVoteCount",
      cell: ({ row }) => getCountIndicator(row.original.PostalVoteCount, row.original.VoterCount),
    },
    {
      header: "PostalOnlyUpdLastYr",
      accessorKey: "PostalOnlyUpdLastYr",
    },
    {
      header: "PostalOnlyLast180d",
      accessorKey: "PostalOnlyLast180d",
    },
    {
      header: "PostalOnlyLast90d",
      accessorKey: "PostalOnlyLast90d",
    },
    {
      header: "PostalOnlyLast4wks",
      accessorKey: "PostalOnlyLast4wks",
    },
    {
      header: "PostalOnlyLastWk",
      accessorKey: "PostalOnlyLastWk",
    },
  ];

  function getDateIndicator(date) {
    if (!date) return <div>-</div>;

    let dateString = date.split("T")[0];

    date = new Date(date);
    const today = new Date();
    const dateDiff = Math.floor((today - date) / 86400000); // ms per day

    if (dateDiff < 90) {
      return (
        <div>
          {dateString} <Icon name="check" size="small" color="green" />
        </div>
      );
    } else if (dateDiff < 180) {
      return (
        <div>
          {dateString} <Icon name="battery half" size="small" color="yellow" />
        </div>
      );
    } else {
      return (
        <div>
          {dateString} <Icon name="exclamation triangle" size="small" color="red" />
        </div>
      );
    }
  }

  function getCountIndicator(pvCount, total) {
    const pvPercent = pvCount / total;
    if (pvPercent < 0.1 || pvPercent > 0.5) {
      return (
        <div>
          {pvCount} <Icon name="exclamation triangle" size="small" color="yellow" />
        </div>
      );
    }
    return pvCount;
  }

  const table = useReactTable({
    columns,
    data: uploadData,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  return (
    <div>
      <Helmet>
        <title>PV Upload Recency</title>
      </Helmet>
      <Dimmer dataLoaded={dataLoaded} error={error}>
        <table>
          {" "}
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <th key={header.id} colSpan={header.colSpan} onClick={header.column.getToggleSortingHandler()}>
                      {
                        <div className={header.column.getCanSort() ? "cursor-pointer select-none" : ""}>
                          {flexRender(header.column.columnDef.header, header.getContext())}
                          {{
                            asc: <Icon name="caret up" color="yellow" />,
                            desc: <Icon name="caret down" color="yellow" />,
                          }[header.column.getIsSorted()] ?? null}
                        </div>
                      }
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => {
              return (
                <tr key={row.id}>
                  {row.getVisibleCells().map((cell) => {
                    return <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>;
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </Dimmer>
    </div>
  );
}
