import React from "react";
import { useParams } from "react-router-dom";
import ScoreProfileCharts from "./ScoreProfileCharts";
import { Grid, GridColumn, GridRow } from "semantic-ui-react";
import { Helmet } from "react-helmet-async";

export default function ScoreProfileScreen({ areaName }) {
  let { areaID } = useParams();

  return (
    <div>
      <Helmet>
        <title>{areaName ?? ""} - Score Profiles</title>
      </Helmet>

      <Grid doubling stackable columns={4} verticalAlign="bottom">
        <GridColumn style={{ minWidth: "450px" }}>
          <ScoreProfileCharts scoreName="Con2019" colour="blue" title="Conservative 2019" />
        </GridColumn>
        <GridColumn style={{ minWidth: "450px" }}>
          <ScoreProfileCharts scoreName="Lab2019" colour="red" title="Labour 2019" />
        </GridColumn>
        <GridColumn style={{ minWidth: "450px" }}>
          <ScoreProfileCharts scoreName="LD2019" colour="#FAA61A" title="Lib Dem 2019" />
        </GridColumn>
        <GridColumn style={{ minWidth: "450px" }}>
          <ScoreProfileCharts scoreName="Rem2019" colour="purple" title="Remain 2019" />
        </GridColumn>
        <GridColumn style={{ minWidth: "450px" }}>
          <ScoreProfileCharts scoreName="Bre2019" colour="#18B9D1" title="Brexit party 2019" />
        </GridColumn>
        {areaID.startsWith("S") ? (
          <GridColumn style={{ minWidth: "450px" }}>
            <ScoreProfileCharts scoreName="SNP2019" colour="yellow" title="SNP 2019" />
          </GridColumn>
        ) : null}
        <GridColumn style={{ minWidth: "450px" }}>
          <ScoreProfileCharts scoreName="Age2024" colour="purple" title="Age 2024" />
        </GridColumn>
      </Grid>
    </div>
  );
}
