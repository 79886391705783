import React, { useState } from "react";
import { Button, ButtonGroup, List, ListContent, ListIcon, ListItem } from "semantic-ui-react";

export default function UserDistrictList({ allDistricts, setSelectedDistricts, userDistricts }) {
  const [activeAreaType, setActiveAreaType] = useState("westminster");

  function renderConstituencies() {
    let userCodes = userDistricts.map((constituency) => {
      return constituency.code;
    });
    let areaConstituencies = allDistricts[activeAreaType] ?? [];

    return areaConstituencies
      .filter((constituency) => {
        return !userCodes.includes(constituency.code);
      })
      .map((constituency) => {
        return (
          <ListItem
            key={`${constituency.name}_${constituency.code}`}
            onClick={() => handleOnClick(constituency.name, constituency.code)}
          >
            <ListIcon name="location arrow" />
            <ListContent>{constituency.name}</ListContent>
          </ListItem>
        );
      });
  }

  function handleOnClick(name, code) {
    setSelectedDistricts((prev) => [...prev, { name, code, districts: getFieldCode(activeAreaType) }]);
  }

  function getFieldCode(areaType) {
    switch (areaType) {
      case "westminster":
        return "W";
      case "westminster2024":
        return "W2024";
      case "european":
        return "ER";
      case "localAuthority":
        return "DBU";
      case "devolvedConstituency":
        return "DevC";
      case "devolvedRegion":
        return "DevR";
      case "Ward":
        return "DBUWR";
      case "countyCouncil":
      case "county":
        return "CR";
      case "countyDivision":
        return "CEDR";
      default:
        return "";
    }
  }

  function getAreaTypeButtons() {
    return Object.keys(allDistricts).map((areaType) => {
      let areaName = areaType.charAt(0).toUpperCase() + areaType.slice(1);
      areaName = areaName.match(/[A-Z][a-z]+|[0-9]+/g).join(" ");
      return (
        <Button
          className="btn"
          key={areaType}
          onClick={() => setActiveAreaType(areaType)}
          color={activeAreaType === areaType ? "yellow" : null}
        >
          {areaName}
        </Button>
      );
    });
  }

  return (
    <div>
      <h3>Select new areas</h3>
      Add by: <ButtonGroup>{getAreaTypeButtons()}</ButtonGroup>
      <br />
      <div style={{ columnCount: "auto", columnWidth: "12rem", width: "85vw", paddingTop: "15px" }}>
        <List>{renderConstituencies()}</List>
      </div>
    </div>
  );
}
